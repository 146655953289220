import React, { ReactNode, useState, useEffect } from "react";
import {
  Box,
  CloseButton,
  Flex,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Collapse,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";

const Layout = ({ children }: { children: ReactNode }) => {
  // new Promise().finally
  return (
    <React.Fragment>
      <Flex width="100vw" h={"calc(100vh - 60px)"}>
        <Box w="250px" flexShrink={0}>
          <SidebarContent />
        </Box>
        <Box w={"calc(100% - 250px)"}>{children}</Box>
      </Flex>
    </React.Fragment>
  );
};

const LinkItems = [
  {
    name: "Contacts",
    path: "/data-sync-hubspot/contact",
  },
  {
    name: "Setup",
    path: "/data-sync-hubspot/setup",
    children: [
      {
        name: "Campaigns",
        path: "/data-sync-hubspot/setup/campaign",
      },
      {
        name: "Products",
        path: "/data-sync-hubspot/setup/product",
      },
      // {
      //   name: "Email Recipients",
      //   path: "/data-sync-hubspot/setup/email-recipient",
      // },
    ],
  },
  // {
  //   name: "Security",
  //   path: "/data-sync-hubspot/security",
  //   children: [
  //     {
  //       name: "Users",
  //       path: '/data-sync-hubspot/security/user'
  //     },
  //     {
  //       name: "Roles",
  //       path: '/data-sync-hubspot/security/role'
  //     },
  //   ]
  // },
  {
    name: "System",
    path: "/data-sync-hubspot/system",
    children: [
      // {
      //   name: "Background Job Monitor",
      //   path: "/data-sync-hubspot/system/background-job-monitor",
      // },
      // {
      //   name: "Historical Transactions",
      //   path: "/data-sync-hubspot/system/historical-transaction",
      // },
      // {
      //   name: "Historical Subscriptions",
      //   path: "/data-sync-hubspot/system/historical-subscription",
      // },
      {
        name: "IPN Processing",
        path: "/data-sync-hubspot/system/pin-processing",
      },
      {
        name: "Transaction Processing",
        path: "/data-sync-hubspot/system/transaction-processing",
      },
      {
        name: "Deal Processing",
        path: "/data-sync-hubspot/system/deal-processing",
      },
    ],
  },
];

const SidebarContent = ({ onClose, ...rest }: any) => {
  const [openNav, setOpenNav] = React.useState("");
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w="full"
      h="full"
      {...rest}
      overflowY={"auto"}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="md" fontFamily="monospace" fontWeight="bold">
          Data Sync Hubspot
        </Text>
      </Flex>
      {LinkItems.map((linkItem) => {
        return (
          <NavItem
            key={linkItem.name}
            item={linkItem}
            hasChildren={linkItem?.children}
            openNav={openNav}
            setOpenNav={setOpenNav}
          />
        );
      })}
    </Box>
  );
};

const NavItem = ({ item, hasChildren }: any) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const isActive =
    location.pathname.includes(item.path) ||
    (item.children &&
      item.children.some((el: any) => location.pathname.includes(el.path)));
  // Synchronize isOpen state with isActive
  useEffect(() => {
    setIsOpen(isActive);
  }, [isActive]);

  const handleClick = () => {
    // if (!isActive)
    setIsOpen(!isOpen);
  };

  const newLocal = "center";
  return (
    <Box>
      {hasChildren ? (
        <Flex
          align="center"
          onClick={handleClick}
          cursor="pointer"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          _hover={{
            bg: "cyan.400",
            color: "white",
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            {/* <Icon as={item.icon} /> */}
            <Text ml="4">{item.name}</Text>
          </Flex>
          <Box>{item.children && <Icon as={FiChevronDown} />}</Box>
        </Flex>
      ) : (
        <Flex
          align="center"
          cursor="pointer"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          as={Link}
          to={item.path}
          _hover={{
            bg: "cyan.400",
            color: "white",
          }}
        >
          {/* <Icon as={item.icon} /> */}
          <Text ml="4">{item.name}</Text>
        </Flex>
      )}
      {item.children && (
        <Collapse in={isOpen}>
          <VStack align="start" pl="14" py={1}>
            {item.children.map((child: any) => (
              <Box
                key={child.name}
                w={"full"}
                py="2"
                px="4"
                borderRadius="lg"
                role="group"
                _hover={{
                  bg: "cyan.400",
                  color: "white",
                }}
                fontSize={12}
                bg={location.pathname === child.path ? "cyan.400" : ""}
                as={Link}
                to={child.path}
              >
                {child.name}
              </Box>
            ))}
          </VStack>
        </Collapse>
      )}
    </Box>
  );
};

export default Layout;
