import React from "react";
import { Flex } from "@chakra-ui/react";
import Layout from "../../components/layout";

const HomePage = () => {
  return (
    <Layout>
      <Flex
        h={"full"}
        w={"full"}
        alignItems={"center"}
        justifyContent={"center"}
        fontSize={"xx-large"}
        fontWeight={"700"}
      >
        Welcome
      </Flex>
    </Layout>
  );
};

export default HomePage;
