export enum LastDealStageEnum {
  NONE = 0,
  PURCHASE = 102424948,
  TRIAL = 90598777,
  CONVERT_FROM_TRIAL = 90598778,
  FIRST_REBILL = 102424949,
  NEW_SUBSCRIPTION = 95416877,
  REBILL = 90598779,
  SUBSCRIPTION_ENDED = 90598780,
  SUBSCRIPTION_REFUND = 102424950,
  PURCHASE_REFUND = 102424951,
  TRIAL_CANCELED = 102424952,
  TRIAL_CANCELED_BY_REFUND = 102424953,
}

export enum DealStatusEnum {
  NotSet = 0,
  Active = 1,
  Trial = 2,
  Canceled = 3,
  Customer = 4,
}

/** PageDTO */
export interface PageDTO<T> {
  records: Array<T>;
  total: number;
  size: number;
  current: number;
  orders: Array<T>;
  optimizeCountSql: boolean;
  searchCount: boolean;
  countId: number;
  maxLimit: number;
  pages: number;
}

/** ContractDTO */
export interface ContractDTO {
  id: number;
  emailAddress: string;
  processDeals: number;
  hubspotContactId: number;
  lastDealStage: number;
  status: number;
  firstName: string;
  lastName: string;
}
