import React from "react";
import Layout from "../../../components/layout";
import { Box } from "@chakra-ui/react";

const BackgroundJobMonitorPage = () => {
  return (
    <Layout>
      <Box p="4">BackgroundJobMonitorPage</Box>
    </Layout>
  );
};

export default BackgroundJobMonitorPage;
