import { getUsersJWT } from "@impact-theory/app-auth";
import { Server } from "./gentype/Server";
import { ContentType, HttpClient } from "./gentype/http-client";

const http = new HttpClient({
  baseURL: process.env.REACT_APP_API_SERVER,
});

http.instance.interceptors.request.use(
  async ({ headers, ...config }) => {
    const jwt = await getUsersJWT();

    headers["Content-Type"] =
      headers["Content-Type"] || ("application/json" as ContentType);
    headers["Authorization"] = `Bearer ${jwt}`;
    return {
      ...config,
      headers,
    };
  },
  (error) => Promise.reject(error)
);

const api = new Server(http);

export default api;
