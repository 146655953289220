import React from "react";
import {
  Checkbox,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

const AgMenu = ({
  columnDefs,
  gridRef,
  setColumnDefs,
}: {
  columnDefs: Array<any>;
  gridRef: React.MutableRefObject<any>;
  setColumnDefs: React.Dispatch<React.SetStateAction<Array<any>>>;
}) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "6px",
        right: "48px",
        background: "#fff",
        zIndex: 99,
      }}
    >
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
        />
        <MenuList>
          {columnDefs &&
            columnDefs.map((el) => (
              <MenuItem key={`menu-item-${el.field}`}>
                <Checkbox
                  onChange={(event) => {
                    if (gridRef && gridRef.current) {
                      setColumnDefs((_columnDefs) => {
                        return _columnDefs.map((cel) => {
                          return {
                            ...cel,
                            visibility:
                              cel.field === el.field
                                ? event.target.checked
                                : cel.visibility,
                          };
                        });
                      });
                      gridRef.current.columnApi.setColumnsVisible(
                        [el.field],
                        event.target.checked
                      );
                    }
                  }}
                  defaultChecked={el.visibility}
                  checked={el.visibility}
                >
                  {el.headerName}
                </Checkbox>
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </div>
  );
};
export default AgMenu;
