import { ChakraProvider, Flex } from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { Heading } from "@chakra-ui/react";
import { userHasAccess } from "@impact-theory/app-auth";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
function App() {
  const [loading, setLoading] = useState(true);

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(["itu-admin"])
        .finally(() => {
          setLoading(false);
        })
        .catch((e) => false);
      setLoggedIn(result);
    }
    run();
  }, []);

  if (loading)
    return (
      <Flex
        h={"200px"}
        w={"100vw"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        loading
      </Flex>
    );

  if (!loggedIn)
    return (
      <div>
        <Heading marginBottom="2rem">No Permission</Heading>
      </div>
    );

  return (
    <Fragment>
      <ChakraProvider>
        <RouterProvider router={router} />
      </ChakraProvider>
    </Fragment>
  );
}

export default App;
