import React, { useState, useEffect, useRef, useMemo } from "react";
import Layout from "../../../components/layout";
import {
  Box,
  Heading,
  Flex,
  ModalHeader,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  IconButton,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import api from "../../../services/api";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-community";
import { ModuleRegistry } from "@ag-grid-community/core";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";

ModuleRegistry.registerModules([SideBarModule]);

const CampaignPage = () => {
  const gridRef = useRef();
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<any>();
  const [deleteId, setDeleteId] = useState<any>();
  const [tableData, setTableData] = useState([]);

  const getPageData = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await api
      .campaignsPageUsingGet({ current: 1, size: -1 })
      .catch((e) => e)
      .finally(() => setLoading(false));
    setTableData(res.data?.data?.records || []);
  };
  useEffect(() => {
    getPageData();
  }, []);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      { headerName: "Campaign ID", field: "campaignId", filter: true },
      {
        headerName: "Campaign Name",
        field: "campaignName",
        flex: 1,
        filter: true,
      },
      {
        headerName: "Action",
        cellRenderer: (params: { data: any }) => {
          return (
            <Flex h={"100%"} alignItems={"center"} gap={4}>
              <IconButton
                size={"sm"}
                aria-label="edit"
                onClick={() => setDetail(params.data)}
                icon={<EditIcon />}
              />

              <IconButton
                colorScheme="red"
                variant="outline"
                size={"sm"}
                aria-label="edit"
                onClick={() => setDeleteId(params.data.id)}
                icon={<DeleteIcon />}
              />
            </Flex>
          );
        },
      },
    ],
    []
  );

  return (
    <Layout>
      {detail ? (
        <Detail
          detail={detail}
          onClose={() => setDetail(null)}
          onRefresh={() => {
            getPageData();
          }}
        />
      ) : null}
      {deleteId ? (
        <DeleteButton
          id={deleteId}
          onClose={() => setDeleteId(null)}
          onRefresh={() => {
            getPageData();
          }}
        />
      ) : null}
      <Box p="4" w="100%" h="100%" position={"relative"}>
        <Flex justifyContent={"space-between"} h={"44px"}>
          <Heading>Campaigns</Heading>
          <Button
            colorScheme="teal"
            variant="solid"
            size="md"
            onClick={() => setDetail({})}
          >
            Add{" "}
          </Button>
        </Flex>
        <div
          className="ag-theme-alpine"
          style={{
            height: "calc(100% - 44px)",
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={tableData}
            columnDefs={columnDefs}
            pagination
            paginationPageSize={20}
          />
        </div>
      </Box>
    </Layout>
  );
};

const DeleteButton = ({
  id,
  onRefresh,
  onClose,
}: {
  id: any;
  onRefresh: () => void;
  onClose: () => void;
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const onDelete = async () => {
    setLoading(true);
    const res = await api
      .removeCampaignsUsingDelete(id)
      .catch((e) => e)
      .finally(() => setLoading(false));
    if (res.data.code === 200) {
      toast({
        status: "success",
        description: "delete success",
      });
      onRefresh();
      onClose();
    } else {
      toast({
        status: "error",
        description: res.data.msg || "error",
      });
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        if (!loading) {
          onClose?.();
        }
      }}
      isCentered
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box py={2}>Are you sure to delete this item?</Box>
        </ModalBody>

        <ModalFooter>
          <Button disabled={loading} mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" isLoading={loading} onClick={onDelete}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const Detail = ({
  detail,
  onRefresh,
  onClose,
}: {
  detail: any;
  onRefresh: () => void;
  onClose: () => void;
}) => {
  const toast = useToast();
  const [data, setData] = useState(detail || {});
  const [loading, setLoading] = useState(false);
  const onSave = async () => {
    setLoading(true);
    const res = await (data.id
      ? api.updateCampaignsUsingPut(data.id, data)
      : api.createCampaignsUsingPost(data)
    )
      .catch((e) => e)
      .finally(() => setLoading(false));
    if (res.data.code === 200) {
      toast({
        status: "success",
        description: data.id ? "save success" : "create success",
      });
      onRefresh();
      onClose();
    } else {
      toast({
        status: "error",
        description: res.data.msg || "error",
      });
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        if (!loading) {
          onClose?.();
        }
      }}
      isCentered
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{data?.id ? "Edit" : "Create"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              Campaign ID:
            </Box>
            <Input
              value={data.campaignId}
              placeholder="please input"
              onChange={(e) => {
                setData({
                  ...data,
                  campaignId: e.target.value,
                });
              }}
            />
          </Box>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w={"130px"} flexShrink={0}>
              Campaign Name:
            </Box>
            <Input
              value={data.campaignName}
              placeholder="please input"
              onChange={(e) => {
                setData({
                  ...data,
                  campaignName: e.target.value,
                });
              }}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button disabled={loading} mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button isLoading={loading} colorScheme="blue" onClick={onSave}>
            {data.id ? "Save" : "Create"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CampaignPage;
