/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AcceptJoinGroupDTO,
  ActivityLikeDTO,
  AddChallengeAwardConfigDTO,
  AddConfigNotificationDTO,
  AddMonthStudentDTO,
  AddUpdateGoalDTO,
  AdminAwardBadgesDTO,
  AdminCreateForumsDTO,
  AdminCreateUserDTO,
  AdminGetAwardDTO,
  AdminResetPassDTO,
  AdminUpdateCourseOrderNumberDTO,
  AdminUpdateCourseStatusDTO,
  AdminUpdateForumsDTO,
  AdminUpdateUserDTO,
  AssignGroupMembersDTO,
  AuditActivityDTO,
  AuditDiscussionDTO,
  AuditReplyDTO,
  BindAccountReqDTO,
  BindTeamToChallengeDTO,
  ChallengeShareWorks,
  ChallengeTask,
  ChallengeTeamMeetingDTO,
  ChangePassWordDTO,
  ChangeStepStatusDTO,
  CommonIdsDTO,
  CoursesDTO,
  CoursesLessonDTO,
  CoursesPhase,
  CoursesTopicDTO,
  CreateAwardTriggersDTO,
  CreateBadgesDTO,
  CreateChallengeDTO,
  CreateChallengeTaskDTO,
  CreateChallengeTeamsDTO,
  CreateCoursesDTO,
  CreateCoursesLessonDTO,
  CreateCoursesMaterialsDTO,
  CreateCoursesPhaseDTO,
  CreateCoursesTopicDTO,
  CreateDirectMessageDTO,
  CreateDiscussionDTO,
  CreateFeatureAccessObjectDTO,
  CreateForumsDTO,
  CreateGroupAlbumDTO,
  CreateGroupDTO,
  CreateGroupMultipleDTO,
  CreateHeroSectionDTO,
  CreateItuPageDTO,
  CreateJournalEntryDTO,
  CreateJournalPromptDTO,
  CreateLeaderboardDTO,
  CreateMembershipDTO,
  CreateMembershipMappingDTO,
  CreateMsCampaignsDTO,
  CreateNewMessageDTO,
  CreateNotificationBannerDTO,
  CreatePointsTypeDTO,
  CreateProductsDTO,
  CreateQuickLinkDTO,
  CreateRankDTO,
  CreateRepliesDTO,
  CreateSeriesDTO,
  CreateShareWorkDTO,
  CreateShareWorkRepliesDTO,
  CreateTaxonomyDTO,
  CreateTimelineDTO,
  CreateTribeEventDTO,
  CreateUserAlbumDTO,
  CreateUserResourceDTO,
  CreateUserVideoMultipleDTO,
  DeleteJournalPromptDTO,
  DeleteLeaderboardDTO,
  DeleteOwnerMessageDTO,
  DeleteThreadMessageDTO,
  DisconnectFriendRequestDTO,
  DiscussionStickyDTO,
  EditDiscussionDTO,
  EditDiscussionReplyDTO,
  EditMessageDTO,
  FollowApproveDTO,
  FollowAskDTO,
  FollowUserDTO,
  ForgotPasswordValidateCodeDTO,
  FriendAcceptOrCancelDTO,
  FriendRequestDTO,
  GetNonceDTO,
  GroupMemberTypeChangeDTO,
  IPNMessageData,
  ItuGroupsType,
  ItuProfileType,
  JoinGroupDTO,
  LeaveGroupDTO,
  LoginParamReqDTO,
  MarkThreadReadDTO,
  MoveResourceToAlbumDTO,
  MsIPNMessageData,
  NewPassWordDTO,
  NotificationBanner,
  OpenForumStatusDTO,
  OrganizerRejectedJoinGroupDTO,
  PinDiscussionDTO,
  PostCommentCreateDTO,
  PostCreateDTO,
  PostPinDTO,
  PostUpdateDTO,
  QuickLink,
  RegisterReqDTO,
  RegisterWeb3ReqDTO,
  RejectedJoinGroupDTO,
  RemoveConfigNotificationDTO,
  RemoveGoalDTO,
  RemoveGoalStepDTO,
  RemoveGroupAlbumDTO,
  RemoveGroupResourceDTO,
  RemoveOwnerAlbumDTO,
  RemovePointsTypeDTO,
  RemoveUserResourceDTO,
  RequestInvitationDTO,
  Resource,
  SendGroupMessageDTO,
  SetNoticeReadDTO,
  SignatureReqDTO,
  StartVideoPlayDTO,
  TimelineIdDTO,
  TopicFavoriteDTO,
  TrackVideoDTO,
  UpdateAwardTriggersDTO,
  UpdateBadgesDTO,
  UpdateBatchDisplayOrderDTO,
  UpdateBatchQuickLinkDTO,
  UpdateChallengeAwardConfigDTO,
  UpdateChallengeDTO,
  UpdateConfigNotificationDTO,
  UpdateFeatureAccessObjectDTO,
  UpdateForumsVideoDTO,
  UpdateGroupCoverPictureDTO,
  UpdateGroupDetailDTO,
  UpdateGroupSettingDTO,
  UpdateGroupZoomDTO,
  UpdateHeroSectionDTO,
  UpdateItuPageDTO,
  UpdateJournalEntryDTO,
  UpdateLeaderboardDTO,
  UpdateMembershipMappingDTO,
  UpdateMonthStudentDTO,
  UpdateMsCampaignsDTO,
  UpdateMsContactsDTO,
  UpdatePointsTypeDTO,
  UpdateProductsDTO,
  UpdateRankDTO,
  UpdateSeriesDTO,
  UpdateTaxonomyDTO,
  UpdateTribeEventDTO,
  UpdateUserMembershipDTO,
  UserAcceptJoinGroupDTO,
  UserBaseInfoDTO,
  UserMetaVo,
  UserRequestInvitationDTO,
  UserSubscribeDTO,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Server<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ITU IPN Listener Controller
   * @name IpnMessageHandlerUsingPost
   * @summary IPN Listener to respond when a payment event is received
   * @request POST:/server/IPN/pks
   */
  ipnMessageHandlerUsingPost = (
    data: {
      transaction_id?: string;
      buyer_email?: string;
      buyer_first_name?: string;
      product_name?: string;
      campaign_name?: string;
      product_id?: string;
      buyer_ip?: string;
      invoice_id?: string;
      transaction_time?: string;
      event?: string;
      verification_code?: string;
      campaign_id?: string;
      tracking_id?: string;
      buyer_last_name?: string;
      hash?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<IPNMessageData, any>({
      path: `/server/IPN/pks`,
      method: "POST",
      body: data,
      type: ContentType.UrlEncoded,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU IPN Hooks Controller
   * @name IpnMessageHooksUsingPost
   * @summary IPN Hooks
   * @request POST:/server/IPNHooks/pks
   */
  ipnMessageHooksUsingPost = (
    data: {
      is_rebill?: string;
      buyer_first_name?: string;
      shipping_state?: string;
      shipping_city?: string;
      shipping_address_1?: string;
      vendor_email?: string;
      shipping_address_2?: string;
      mode?: string;
      campaign_name?: string;
      coupon_rate?: string;
      billing_state?: string;
      shipping_zip?: string;
      billing_address_1?: string;
      billing_address_2?: string;
      product_id?: string;
      billing_zip?: string;
      buyer_ip?: string;
      invoice_id?: string;
      transaction_time?: string;
      event?: string;
      affiliate_commission_amount?: string;
      campaign_id?: string;
      tracking_id?: string;
      transaction_id?: string;
      affiliate_first_name?: string;
      buyer_email?: string;
      amount?: string;
      coupon_code?: string;
      quantity?: string;
      vendor_first_name?: string;
      billing_country?: string;
      product_name?: string;
      billing_city?: string;
      affiliate_email?: string;
      licenses?: string;
      custom_var2?: string;
      affiliate_last_name?: string;
      custom_var1?: string;
      affiliate_commission_percent?: string;
      vendor_last_name?: string;
      payment_processor?: string;
      verification_code?: string;
      shipping_country?: string;
      hash?: string;
      buyer_last_name?: string;
      coupon_type?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<MsIPNMessageData, any>({
      path: `/server/IPNHooks/pks`,
      method: "POST",
      body: data,
      type: ContentType.UrlEncoded,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Access Controller
   * @name GetStpUserFeatureAccessUsingGet
   * @summary getStpUserFeatureAccess
   * @request GET:/server/access/owner_access
   */
  getStpUserFeatureAccessUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/access/owner_access`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Access Controller
   * @name CreateFeatureAccessUsingPost
   * @summary create feature access objects
   * @request POST:/server/admin/access/create_feature_object
   */
  createFeatureAccessUsingPost = (
    dto: CreateFeatureAccessObjectDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/access/create_feature_object`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Access Controller
   * @name FeatureListUsingGet
   * @summary feature access objects
   * @request GET:/server/admin/access/list
   */
  featureListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/access/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Access Controller
   * @name UpdateFeatureAccessUsingPost
   * @summary update feature access objects
   * @request POST:/server/admin/access/update_feature_object
   */
  updateFeatureAccessUsingPost = (
    dto: UpdateFeatureAccessObjectDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/access/update_feature_object`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name BindChallengeTeamsUsingPost
   * @summary bind challenge teams
   * @request POST:/server/admin/challenge/bind_challenge_teams
   */
  bindChallengeTeamsUsingPost = (
    dto: BindTeamToChallengeDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/bind_challenge_teams`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name BookTeamDiscussionUsingPost
   * @summary book team discussion
   * @request POST:/server/admin/challenge/book_team_discussion/{discussionId}
   */
  bookTeamDiscussionUsingPost = (
    discussionId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/book_team_discussion/${discussionId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name ChallengeAssignmentListUsingGet
   * @summary get challenge assigment list
   * @request GET:/server/admin/challenge/challenge_assignment_page
   */
  challengeAssignmentListUsingGet = (
    query?: {
      /**
       * challengeId
       * @format int64
       */
      challengeId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** endDate */
      endDate?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** startDate */
      startDate?: string;
      /**
       * taskId
       * @format int64
       */
      taskId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/challenge_assignment_page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name CreateChallengeUsingPost
   * @summary create challenge
   * @request POST:/server/admin/challenge/create_challenge
   */
  createChallengeUsingPost = (
    dto: CreateChallengeDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/create_challenge`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name CreateChallengeTaskUsingPost
   * @summary create challenge task
   * @request POST:/server/admin/challenge/create_challenge_task
   */
  createChallengeTaskUsingPost = (
    dto: CreateChallengeTaskDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/create_challenge_task`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name CreateChallengeTeamMeetingUsingPost
   * @summary create challenge team meeting
   * @request POST:/server/admin/challenge/create_challenge_team_meeting
   */
  createChallengeTeamMeetingUsingPost = (
    dto: ChallengeTeamMeetingDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/create_challenge_team_meeting`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name CreateChallengeTeamsUsingPost
   * @summary create challenge teams
   * @request POST:/server/admin/challenge/create_challenge_teams
   */
  createChallengeTeamsUsingPost = (
    dto: CreateChallengeTeamsDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/create_challenge_teams`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name DeleteChallengeUsingPost
   * @summary delete challenge
   * @request POST:/server/admin/challenge/delete_challenge/{challengeId}
   */
  deleteChallengeUsingPost = (
    challengeId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/delete_challenge/${challengeId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name ChallengeListUsingGet
   * @summary challenge list
   * @request GET:/server/admin/challenge/list
   */
  challengeListUsingGet = (
    query?: {
      /**
       * challengeId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      challengeId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** name */
      name?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name PinTeamDiscussionUsingPost
   * @summary pin team discussion
   * @request POST:/server/admin/challenge/pin_team_discussion/{discussionId}
   */
  pinTeamDiscussionUsingPost = (
    discussionId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/pin_team_discussion/${discussionId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name ChallengeTaskListUsingGet
   * @summary task list
   * @request GET:/server/admin/challenge/task_list
   */
  challengeTaskListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** name */
      name?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * taskId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      taskId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/task_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name UnBookTeamDiscussionUsingPost
   * @summary un book team discussion
   * @request POST:/server/admin/challenge/un_book_team_discussion/{discussionId}
   */
  unBookTeamDiscussionUsingPost = (
    discussionId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/un_book_team_discussion/${discussionId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name UnPinTeamDiscussionUsingPost
   * @summary pin team discussion
   * @request POST:/server/admin/challenge/un_pin_team_discussion/{discussionId}
   */
  unPinTeamDiscussionUsingPost = (
    discussionId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/un_pin_team_discussion/${discussionId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name UnbindChallengeTeamsUsingPost
   * @summary un bind challenge teams
   * @request POST:/server/admin/challenge/unbind_challenge_teams
   */
  unbindChallengeTeamsUsingPost = (
    dto: BindTeamToChallengeDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/unbind_challenge_teams`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name UpdateChallengeUsingPost
   * @summary update challenge
   * @request POST:/server/admin/challenge/update_challenge
   */
  updateChallengeUsingPost = (
    dto: UpdateChallengeDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/update_challenge`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name UpdateChallengeTaskUsingPost
   * @summary update challenge task
   * @request POST:/server/admin/challenge/update_challenge_task
   */
  updateChallengeTaskUsingPost = (
    task: ChallengeTask,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/update_challenge_task`,
      method: "POST",
      body: task,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Challenge Controller
   * @name UpdateChallengeTeamMeetingUsingPost
   * @summary update challenge team meeting
   * @request POST:/server/admin/challenge/update_challenge_team_meeting
   */
  updateChallengeTeamMeetingUsingPost = (
    dto: ChallengeTeamMeetingDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge/update_challenge_team_meeting`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Challenge Award Config
   * @name AddChallengeAwardConfigUsingPost
   * @summary add challenge award config
   * @request POST:/server/admin/challenge_award/create_award_config
   */
  addChallengeAwardConfigUsingPost = (
    dto: AddChallengeAwardConfigDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge_award/create_award_config`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Challenge Award Config
   * @name ListByTaskIdUsingGet
   * @summary get award config by taskId
   * @request GET:/server/admin/challenge_award/listByTaskId
   */
  listByTaskIdUsingGet = (
    query: {
      /**
       * taskId
       * @format int64
       */
      taskId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge_award/listByTaskId`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Challenge Award Config
   * @name RemoveConfigUsingPost
   * @summary remove config
   * @request POST:/server/admin/challenge_award/remove_config/{id}
   */
  removeConfigUsingPost = (id: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge_award/remove_config/${id}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Challenge Award Config
   * @name UpdateChallengeAwardConfigComplexUsingPost
   * @summary update challenge award config Complex
   * @request POST:/server/admin/challenge_award/update_award_config_complex
   */
  updateChallengeAwardConfigComplexUsingPost = (
    dtoList: UpdateChallengeAwardConfigDTO[],
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/challenge_award/update_award_config_complex`,
      method: "POST",
      body: dtoList,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Config Notification Controller
   * @name ConfigNotificationEnumUsingGet
   * @summary notification config enum
   * @request GET:/server/admin/config/notification/config_enum
   */
  configNotificationEnumUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/config/notification/config_enum`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Config Notification Controller
   * @name ConfigNotificationListUsingGet
   * @summary notification config list
   * @request GET:/server/admin/config/notification/config_list
   */
  configNotificationListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/config/notification/config_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Config Notification Controller
   * @name CreateConfigNotificationUsingPost
   * @summary create notification config
   * @request POST:/server/admin/config/notification/create
   */
  createConfigNotificationUsingPost = (
    dto: AddConfigNotificationDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/config/notification/create`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Config Notification Controller
   * @name RemoveConfigNotificationUsingPost
   * @summary delete notification config
   * @request POST:/server/admin/config/notification/delete
   */
  removeConfigNotificationUsingPost = (
    dto: RemoveConfigNotificationDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/config/notification/delete`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Config Notification Controller
   * @name UpdateConfigNotificationUsingPost
   * @summary update notification config
   * @request POST:/server/admin/config/notification/update
   */
  updateConfigNotificationUsingPost = (
    dto: UpdateConfigNotificationDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/config/notification/update`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name CoursesDetailUsingGet
   * @summary Get courses detail
   * @request GET:/server/admin/courses/courses_detail
   */
  coursesDetailUsingGet = (
    query: {
      /**
       * coursesId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      coursesId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/courses_detail`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name CoursesListUsingGet
   * @summary Get courses list
   * @request GET:/server/admin/courses/courses_list
   */
  coursesListUsingGet = (
    query?: {
      /**
       * coursesId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      coursesId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** isBonus */
      isBonus?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** title */
      title?: string;
      /** type */
      type?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/courses_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name GetCoursesByLevelUsingGet
   * @summary Get courses list_by_level
   * @request GET:/server/admin/courses/courses_list_by_level
   */
  getCoursesByLevelUsingGet = (
    query?: {
      /**
       * levelIds
       * @default "1"
       */
      levelIds?: number[];
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/courses_list_by_level`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name CreateCoursesUsingPost
   * @summary create courses
   * @request POST:/server/admin/courses/create_courses
   */
  createCoursesUsingPost = (
    dto: CreateCoursesDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/create_courses`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name CreateCoursesLessonUsingPost
   * @summary create lesson
   * @request POST:/server/admin/courses/create_courses_lesson
   */
  createCoursesLessonUsingPost = (
    dto: CreateCoursesLessonDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/create_courses_lesson`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name CreateCoursesMaterialsUsingPost
   * @summary create materials
   * @request POST:/server/admin/courses/create_courses_materials
   */
  createCoursesMaterialsUsingPost = (
    dto: CreateCoursesMaterialsDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/create_courses_materials`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name CreateCoursesPhaseUsingPost
   * @summary create phase
   * @request POST:/server/admin/courses/create_courses_phase
   */
  createCoursesPhaseUsingPost = (
    dto: CreateCoursesPhaseDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/create_courses_phase`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name CreateCoursesTopicUsingPost
   * @summary create topic
   * @request POST:/server/admin/courses/create_courses_topic
   */
  createCoursesTopicUsingPost = (
    dto: CreateCoursesTopicDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/create_courses_topic`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name DeleteCoursesUsingPost
   * @summary delete courses
   * @request POST:/server/admin/courses/delete_courses
   */
  deleteCoursesUsingPost = (
    params: Record<string, string>,
    requestParams: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/delete_courses`,
      method: "POST",
      body: params,
      type: ContentType.Json,
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name DeleteLessonUsingPost
   * @summary delete lesson
   * @request POST:/server/admin/courses/delete_lesson
   */
  deleteLessonUsingPost = (
    params: Record<string, string>,
    requestParams: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/delete_lesson`,
      method: "POST",
      body: params,
      type: ContentType.Json,
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name DeleteMaterialsUsingPost
   * @summary delete materials
   * @request POST:/server/admin/courses/delete_materials
   */
  deleteMaterialsUsingPost = (
    params: Record<string, string>,
    requestParams: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/delete_materials`,
      method: "POST",
      body: params,
      type: ContentType.Json,
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name DeletePhaseUsingPost
   * @summary delete phase
   * @request POST:/server/admin/courses/delete_phase
   */
  deletePhaseUsingPost = (
    params: Record<string, string>,
    requestParams: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/delete_phase`,
      method: "POST",
      body: params,
      type: ContentType.Json,
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name DeleteTopicUsingPost
   * @summary delete topic
   * @request POST:/server/admin/courses/delete_topic
   */
  deleteTopicUsingPost = (
    params: Record<string, string>,
    requestParams: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/delete_topic`,
      method: "POST",
      body: params,
      type: ContentType.Json,
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name LessonListUsingGet
   * @summary Get lesson list
   * @request GET:/server/admin/courses/lesson_list
   */
  lessonListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * lessonId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      lessonId?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** title */
      title?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/lesson_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name PhaseListUsingGet
   * @summary Get phase list
   * @request GET:/server/admin/courses/phase_list
   */
  phaseListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * phaseId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      phaseId?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** title */
      title?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/phase_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name TopicListUsingGet
   * @summary Get topic list
   * @request GET:/server/admin/courses/topic_list
   */
  topicListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** title */
      title?: string;
      /**
       * topicId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      topicId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/topic_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name UpdateCourseOrderNumberUsingPost
   * @summary update course order number
   * @request POST:/server/admin/courses/update_course_order_number
   */
  updateCourseOrderNumberUsingPost = (
    dto: AdminUpdateCourseOrderNumberDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/update_course_order_number`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name UpdateCoursesUsingPost
   * @summary update courses
   * @request POST:/server/admin/courses/update_courses
   */
  updateCoursesUsingPost = (courses: CoursesDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/update_courses`,
      method: "POST",
      body: courses,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name UpdateCourseStatusUsingPost
   * @summary update course status
   * @request POST:/server/admin/courses/update_courses_status
   */
  updateCourseStatusUsingPost = (
    dto: AdminUpdateCourseStatusDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/update_courses_status`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name UpdateLessonUsingPost
   * @summary update lesson
   * @request POST:/server/admin/courses/update_lesson
   */
  updateLessonUsingPost = (
    lesson: CoursesLessonDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/update_lesson`,
      method: "POST",
      body: lesson,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name UpdatePhaseUsingPost
   * @summary update phase
   * @request POST:/server/admin/courses/update_phase
   */
  updatePhaseUsingPost = (phase: CoursesPhase, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/update_phase`,
      method: "POST",
      body: phase,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Courses Controller
   * @name UpdateTopicUsingPost
   * @summary update topic
   * @request POST:/server/admin/courses/update_topic
   */
  updateTopicUsingPost = (topic: CoursesTopicDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/courses/update_topic`,
      method: "POST",
      body: topic,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Forums Controller
   * @name CreateForumsUsingPost
   * @summary create new forum
   * @request POST:/server/admin/forums/create_forum
   */
  createForumsUsingPost = (
    dto: AdminCreateForumsDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/forums/create_forum`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Forums Controller
   * @name DeleteForumsUsingDelete
   * @summary /delete forums
   * @request DELETE:/server/admin/forums/delete
   */
  deleteForumsUsingDelete = (ids: number[], params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/forums/delete`,
      method: "DELETE",
      body: ids,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Forums Controller
   * @name PageDiscussionUsingGet
   * @summary Get a list of all discussion
   * @request GET:/server/admin/forums/discussion_list
   */
  pageDiscussionUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * discussionId
       * @format int64
       */
      discussionId?: number;
      /** discussionName */
      discussionName?: string;
      /**
       * forumId
       * @format int64
       */
      forumId?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/forums/discussion_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Forums Controller
   * @name PageForumsUsingGet
   * @summary Get a list of all forums
   * @request GET:/server/admin/forums/forums_list
   */
  pageForumsUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * forumId
       * @format int64
       */
      forumId?: number;
      /** forumName */
      forumName?: string;
      /** showVideo */
      showVideo?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/forums/forums_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Forums Controller
   * @name UpdateForumsUsingPost
   * @summary update forum
   * @request POST:/server/admin/forums/update_forum
   */
  updateForumsUsingPost = (
    dto: AdminUpdateForumsDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/forums/update_forum`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Forums Controller
   * @name UpdateVideoStatusUsingPost
   * @summary update forum video status
   * @request POST:/server/admin/forums/update_video_status
   */
  updateVideoStatusUsingPost = (
    dto: UpdateForumsVideoDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/forums/update_video_status`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name AdminAwardBadgesUsingPost
   * @summary Admin: Award badges to a user
   * @request POST:/server/admin/gamification/admin_award_badge
   */
  adminAwardBadgesUsingPost = (
    dto: AdminAwardBadgesDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/admin_award_badge`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name AdminGetAwardUsingPost
   * @summary Admin: Award points to a user
   * @request POST:/server/admin/gamification/admin_award_user
   */
  adminGetAwardUsingPost = (
    dto: AdminGetAwardDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/admin_award_user`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name AwardTriggerEventListUsingGet
   * @summary trigger event list
   * @request GET:/server/admin/gamification/award_trigger_event
   */
  awardTriggerEventListUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/award_trigger_event`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name ListAwardTriggerUsingGet
   * @summary awardTrigger list
   * @request GET:/server/admin/gamification/award_trigger_list
   */
  listAwardTriggerUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/award_trigger_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name BadgesPageUsingGet
   * @summary badges list
   * @request GET:/server/admin/gamification/badges
   */
  badgesPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/badges`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name CreateAwardTriggerUsingPost
   * @summary create AwardTrigger
   * @request POST:/server/admin/gamification/create_award_trigger
   */
  createAwardTriggerUsingPost = (
    dto: CreateAwardTriggersDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/create_award_trigger`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name CreateBadgesUsingPost
   * @summary create badges
   * @request POST:/server/admin/gamification/create_badges
   */
  createBadgesUsingPost = (dto: CreateBadgesDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/create_badges`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name CreateLeaderboardUsingPost
   * @summary create leaderboard
   * @request POST:/server/admin/gamification/create_leaderboard
   */
  createLeaderboardUsingPost = (
    dto: CreateLeaderboardDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/create_leaderboard`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name CreatePointsTypeUsingPost
   * @summary create points type
   * @request POST:/server/admin/gamification/create_points_type
   */
  createPointsTypeUsingPost = (
    dto: CreatePointsTypeDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/create_points_type`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name CreateRankUsingPost
   * @summary update rank
   * @request POST:/server/admin/gamification/create_rank
   */
  createRankUsingPost = (dto: CreateRankDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/create_rank`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name DeleteLeaderboardUsingPost
   * @summary delete leaderboard
   * @request POST:/server/admin/gamification/delete_leaderboard
   */
  deleteLeaderboardUsingPost = (
    dto: DeleteLeaderboardDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/delete_leaderboard`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name GamificationReportsUsingGet
   * @summary Gamification reports
   * @request GET:/server/admin/gamification/gamification_reports
   */
  gamificationReportsUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/gamification_reports`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name GamificationUserPointTotalUsingGet
   * @summary Show total points by point type by user
   * @request GET:/server/admin/gamification/gamification_user_point_total
   */
  gamificationUserPointTotalUsingGet = (
    query: {
      /**
       * userId
       * @format int64
       */
      userId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/gamification_user_point_total`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name LeaderBoardPageUsingGet
   * @summary leaderboard list
   * @request GET:/server/admin/gamification/leaderboard
   */
  leaderBoardPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/leaderboard`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name PointTypeListUsingGet
   * @summary points type list
   * @request GET:/server/admin/gamification/points_type_list
   */
  pointTypeListUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/points_type_list`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name RankPageUsingGet
   * @summary rank list
   * @request GET:/server/admin/gamification/ranks
   */
  rankPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/ranks`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name RemovePointsTypeUsingPost
   * @summary remove points type
   * @request POST:/server/admin/gamification/remove_points_type
   */
  removePointsTypeUsingPost = (
    dto: RemovePointsTypeDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/remove_points_type`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name UpdateAwardTriggerUsingPost
   * @summary update AwardTrigger
   * @request POST:/server/admin/gamification/update_award_trigger
   */
  updateAwardTriggerUsingPost = (
    dto: UpdateAwardTriggersDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/update_award_trigger`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name UpdateBadgesUsingPost
   * @summary update badges
   * @request POST:/server/admin/gamification/update_badges
   */
  updateBadgesUsingPost = (dto: UpdateBadgesDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/update_badges`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name CreateLeaderboardUsingPost1
   * @summary update leaderboard
   * @request POST:/server/admin/gamification/update_leaderboard
   */
  createLeaderboardUsingPost1 = (
    dto: UpdateLeaderboardDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/update_leaderboard`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name UpdatePointsTypeUsingPost
   * @summary update points type
   * @request POST:/server/admin/gamification/update_points_type
   */
  updatePointsTypeUsingPost = (
    dto: UpdatePointsTypeDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/update_points_type`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name UpdateRankUsingPost
   * @summary update rank
   * @request POST:/server/admin/gamification/update_rank
   */
  updateRankUsingPost = (dto: UpdateRankDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/update_rank`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name UserAwardPageUsingGet
   * @summary user award page
   * @request GET:/server/admin/gamification/user_award_page
   */
  userAwardPageUsingGet = (
    query?: {
      /** awardType */
      awardType?: "BADGE" | "POINT_TYPE" | "RANK";
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/user_award_page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name UserBadgeListUsingGet
   * @summary Show user's badge list
   * @request GET:/server/admin/gamification/user_badge_list
   */
  userBadgeListUsingGet = (
    query: {
      /**
       * userId
       * @format int64
       */
      userId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/user_badge_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name UserChallengePointsDetailsUsingGet
   * @summary Show user's Challenge points details
   * @request GET:/server/admin/gamification/user_challenge_points_detail
   */
  userChallengePointsDetailsUsingGet = (
    query: {
      /**
       * userId
       * @format int64
       */
      userId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/user_challenge_points_detail`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Gamification Controller
   * @name UserImpactPointsDetailsUsingGet
   * @summary Show user's import points details
   * @request GET:/server/admin/gamification/user_import_points_detail
   */
  userImpactPointsDetailsUsingGet = (
    query: {
      /**
       * userId
       * @format int64
       */
      userId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/gamification/user_import_points_detail`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Goal Controller
   * @name GoalListUsingGet
   * @summary Goals List
   * @request GET:/server/admin/goal/user_goal
   */
  goalListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/goal/user_goal`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Groups Controller
   * @name CreateGroupUsingPost
   * @summary create or update group
   * @request POST:/server/admin/groups/create_group
   */
  createGroupUsingPost = (
    createGroupDTO: CreateGroupDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/groups/create_group`,
      method: "POST",
      body: createGroupDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Groups Controller
   * @name ListGroupCourseUsingGet
   * @summary group challenge course list
   * @request GET:/server/admin/groups/get_group_course
   */
  listGroupCourseUsingGet = (
    query: {
      /**
       * groupId
       * @format int64
       */
      groupId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/groups/get_group_course`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Groups Controller
   * @name AssignGroupMembersUsingPost
   * @summary join group
   * @request POST:/server/admin/groups/join_group
   */
  assignGroupMembersUsingPost = (
    dto: AssignGroupMembersDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/groups/join_group`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Groups Controller
   * @name ListGroupUsingGet
   * @summary IMC get information about group
   * @request GET:/server/admin/groups/list
   */
  listGroupUsingGet = (
    query?: {
      /**
       * challengeId
       * @format int64
       */
      challengeId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * groupId
       * @format int64
       */
      groupId?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/groups/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Groups Controller
   * @name DeleteGroupTypeUsingPost
   * @summary delete group type by id
   * @request POST:/server/admin/groups/remove_group_type/{id}
   */
  deleteGroupTypeUsingPost = (id: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/groups/remove_group_type/${id}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Groups Controller
   * @name AddOrUpdateGroupTypeUsingPost
   * @summary add or modify group type data
   * @request POST:/server/admin/groups/update_group_type
   */
  addOrUpdateGroupTypeUsingPost = (
    ituGroupsType: ItuGroupsType,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/groups/update_group_type`,
      method: "POST",
      body: ituGroupsType,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Hero Section Controller
   * @name SelectCategoriesUsingGet
   * @summary Get hero section category list
   * @request GET:/server/admin/hero_section/category/list
   */
  selectCategoriesUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/hero_section/category/list`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Hero Section Controller
   * @name CreateHeroSectionUsingPost
   * @summary create hero section
   * @request POST:/server/admin/hero_section/create
   */
  createHeroSectionUsingPost = (
    request: CreateHeroSectionDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/hero_section/create`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Hero Section Controller
   * @name DeleteEventUsingPost
   * @summary delete hero section
   * @request POST:/server/admin/hero_section/delete/{id}
   */
  deleteEventUsingPost = (id: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/hero_section/delete/${id}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Hero Section Controller
   * @name SelectHeroSectionsUsingGet
   * @summary Get hero section list
   * @request GET:/server/admin/hero_section/list
   */
  selectHeroSectionsUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** isActive */
      isActive?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** title */
      title?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/hero_section/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Hero Section Controller
   * @name UpdateEventUsingPost
   * @summary update hero section
   * @request POST:/server/admin/hero_section/update
   */
  updateEventUsingPost = (
    request: UpdateHeroSectionDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/hero_section/update`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Hero Section Controller
   * @name UpdateBatchOrderUsingPost
   * @summary update batch hero section
   * @request POST:/server/admin/hero_section/updateBatch
   */
  updateBatchOrderUsingPost = (
    request: UpdateBatchDisplayOrderDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/hero_section/updateBatch`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Journal Controller
   * @name CreateOrUpdatePromptUsingPost
   * @summary create or update journal prompt
   * @request POST:/server/admin/journal/create_update_prompt
   */
  createOrUpdatePromptUsingPost = (
    dto: CreateJournalPromptDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/journal/create_update_prompt`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Journal Controller
   * @name DeleteJournalPromptUsingPost
   * @summary delete prompt
   * @request POST:/server/admin/journal/delete_prompt
   */
  deleteJournalPromptUsingPost = (
    dto: DeleteJournalPromptDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/journal/delete_prompt`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Journal Controller
   * @name PagePromptUsingGet
   * @summary pagePrompt
   * @request GET:/server/admin/journal/prompt_page
   */
  pagePromptUsingGet = (
    query?: {
      /** active */
      active?: boolean;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** endDate */
      endDate?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** startDate */
      startDate?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/journal/prompt_page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Membership Controller
   * @name CreateNewMemberShipUsingPost
   * @summary create or update membership level
   * @request POST:/server/admin/membership/create_membership
   */
  createNewMemberShipUsingPost = (
    dto: CreateMembershipDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/membership/create_membership`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Membership Controller
   * @name MembershipLevelPageUsingGet
   * @summary membership level info list
   * @request GET:/server/admin/membership/level/list
   */
  membershipLevelPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 50
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/membership/level/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Membership Controller
   * @name CreateMembershipMappingUsingPost
   * @summary create membership level and product mapping
   * @request POST:/server/admin/membership/mapping/create
   */
  createMembershipMappingUsingPost = (
    dto: CreateMembershipMappingDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/membership/mapping/create`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Membership Controller
   * @name MembershipMappingPageUsingGet
   * @summary membership level and product mapping
   * @request GET:/server/admin/membership/mapping/list
   */
  membershipMappingPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 50
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/membership/mapping/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Membership Controller
   * @name DeleteMembershipMappingUsingDelete
   * @summary delete membership level and product mapping
   * @request DELETE:/server/admin/membership/mapping/remove/{id}
   */
  deleteMembershipMappingUsingDelete = (
    id: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/membership/mapping/remove/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Membership Controller
   * @name UpdateMembershipMappingUsingPost
   * @summary update membership level and product mapping
   * @request POST:/server/admin/membership/mapping/update
   */
  updateMembershipMappingUsingPost = (
    dto: UpdateMembershipMappingDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/membership/mapping/update`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Membership Controller
   * @name AddOrUpdateUserMembershipLevelUsingPost
   * @summary add or update user membership level
   * @request POST:/server/admin/membership/update_user_membership
   */
  addOrUpdateUserMembershipLevelUsingPost = (
    dto: UpdateUserMembershipDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/membership/update_user_membership`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserCompletionUsingPost
   * @summary calculate user profile completion
   * @request POST:/server/admin/migration/calculate_user_profile_completion
   */
  migrateUserCompletionUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/calculate_user_profile_completion`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name CoursesDealUsingPost
   * @summary 处理courses_topic表中的WordPress标签和附件url转换
   * @request POST:/server/admin/migration/courses_topic_Deal
   */
  coursesDealUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/courses_topic_Deal`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name GetItuForumsUsingGet
   * @summary test get wordpress fourm data
   * @request GET:/server/admin/migration/getItuForums
   */
  getItuForumsUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/getItuForums`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name HandleDupNickNameDataUsingPost
   * @summary handle dup's nick name data
   * @request POST:/server/admin/migration/handle_dup_nick_name_data
   */
  handleDupNickNameDataUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/handle_dup_nick_name_data`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name InitRedisMessageUsingPost
   * @summary migrate message to redis cache
   * @request POST:/server/admin/migration/initRedisMessage
   */
  initRedisMessageUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/initRedisMessage`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name InitReplyChildReplyIdsUsingPost
   * @summary init child_reply_ids
   * @request POST:/server/admin/migration/init_reply_child_reply_ids
   */
  initReplyChildReplyIdsUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/init_reply_child_reply_ids`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateChallengeUsingGet
   * @summary MigrateChallenge
   * @request GET:/server/admin/migration/migrate/challenge
   */
  migrateChallengeUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/challenge`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateAwardConfigUsingGet
   * @summary MigrateAwardConfig
   * @request GET:/server/admin/migration/migrate/challenge/award/config
   */
  migrateAwardConfigUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/challenge/award/config`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateChallengeAwardRecordUsingGet
   * @summary migrateChallengeAwardRecord
   * @request GET:/server/admin/migration/migrate/challenge/award/record
   */
  migrateChallengeAwardRecordUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/challenge/award/record`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateCoursesUsingGet
   * @summary 迁移课程1库
   * @request GET:/server/admin/migration/migrate/courses
   */
  migrateCoursesUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/courses`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateCoursesMemberShipLevelUsingGet
   * @summary MigrateCoursesMemberShipLevel
   * @request GET:/server/admin/migration/migrate/courses/level
   */
  migrateCoursesMemberShipLevelUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/courses/level`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateCourses2UsingGet
   * @summary 迁移课程2库
   * @request GET:/server/admin/migration/migrate/courses_2
   */
  migrateCourses2UsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/courses_2`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateEventUsingGet
   * @summary 迁移event
   * @request GET:/server/admin/migration/migrate/event
   */
  migrateEventUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/event`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateHubspotChallengeEventUsingPost
   * @summary 迁移数据库中挑战任务到 Hubspot 中
   * @request POST:/server/admin/migration/migrate/hubspot/challenge/event
   */
  migrateHubspotChallengeEventUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/challenge/event`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateHubspotGroupEventUsingPost
   * @summary 迁移数据库中加入和离开 Group 的数据到 Hubspot 中
   * @request POST:/server/admin/migration/migrate/hubspot/group/event
   */
  migrateHubspotGroupEventUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/group/event`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateHubspotMessageEventUsingPost
   * @summary 迁移数据库中已读消息的数据到 Hubspot 中
   * @request POST:/server/admin/migration/migrate/hubspot/message/event
   */
  migrateHubspotMessageEventUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/message/event`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserCommentToHubspotUsingPost
   * @summary 迁移数据库中Post-Comment到Hubspot中
   * @request POST:/server/admin/migration/migrate/hubspot/user/comment
   */
  migrateUserCommentToHubspotUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/user/comment`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserCompleteCoursesEventToHubspotUsingPost
   * @summary 迁移数据库中用户课程完成进度到Hubspot中
   * @request POST:/server/admin/migration/migrate/hubspot/user/complete/course
   */
  migrateUserCompleteCoursesEventToHubspotUsingPost = (
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/user/complete/course`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserFollowToHubspotUsingPost
   * @summary 迁移数据库中用户Follow到Hubspot中
   * @request POST:/server/admin/migration/migrate/hubspot/user/follow
   */
  migrateUserFollowToHubspotUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/user/follow`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserLikeToHubspotUsingPost
   * @summary 迁移数据库中用户Like到Hubspot中
   * @request POST:/server/admin/migration/migrate/hubspot/user/like
   */
  migrateUserLikeToHubspotUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/user/like`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserEventToHubspotUsingPost
   * @summary 迁移数据库中用户Post到Hubspot中
   * @request POST:/server/admin/migration/migrate/hubspot/user/post
   */
  migrateUserEventToHubspotUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/user/post`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserDiscussionToHubspotUsingPost
   * @summary 迁移数据库中用户发送的Discussion到Hubspot中
   * @request POST:/server/admin/migration/migrate/hubspot/user/postDiscussion
   */
  migrateUserDiscussionToHubspotUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/user/postDiscussion`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserMessageToHubspotUsingPost
   * @summary 迁移数据库中用户发送的Message到Hubspot中
   * @request POST:/server/admin/migration/migrate/hubspot/user/postMessage
   */
  migrateUserMessageToHubspotUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/user/postMessage`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserReplyToHubspotUsingPost
   * @summary 迁移数据库中用户发送的Reply到Hubspot中
   * @request POST:/server/admin/migration/migrate/hubspot/user/postReply
   */
  migrateUserReplyToHubspotUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/user/postReply`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserViewVideoToHubspotUsingPost
   * @summary 迁移数据库中用户观看视频历史到Hubspot中
   * @request POST:/server/admin/migration/migrate/hubspot/user/viewVideo
   */
  migrateUserViewVideoToHubspotUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/user/viewVideo`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateZoomDataToHubspotUsingPost
   * @summary 迁移zoom信息到hubspot
   * @request POST:/server/admin/migration/migrate/hubspot/user/zoom
   */
  migrateZoomDataToHubspotUsingPost = (
    query: {
      /** from */
      from: string;
      /** to */
      to: string;
      /** userId */
      userId: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/hubspot/user/zoom`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateTaxonomyUsingGet
   * @summary MigrateTaxonomy
   * @request GET:/server/admin/migration/migrate/taxonomy
   */
  migrateTaxonomyUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate/taxonomy`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateActivityUsingPost
   * @summary migrateActivity
   * @request POST:/server/admin/migration/migrate_activity
   */
  migrateActivityUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_activity`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateBpMessageUsingPost
   * @summary migrate message data
   * @request POST:/server/admin/migration/migrate_bpMessage
   */
  migrateBpMessageUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_bpMessage`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateDiscussionReplyUsingPost
   * @summary migrate discussion reply data
   * @request POST:/server/admin/migration/migrate_discussion_reply
   */
  migrateDiscussionReplyUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_discussion_reply`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateDiscussionsUsingPost
   * @summary migrate discussion data
   * @request POST:/server/admin/migration/migrate_discussions
   */
  migrateDiscussionsUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_discussions`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateFollowerUsingPost
   * @summary migrate follower data
   * @request POST:/server/admin/migration/migrate_follower
   */
  migrateFollowerUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_follower`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateForumInfoUsingPost
   * @summary migrate forum data
   * @request POST:/server/admin/migration/migrate_forum
   */
  migrateForumInfoUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_forum`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateFriendUsingPost
   * @summary migrate friend data
   * @request POST:/server/admin/migration/migrate_friend
   */
  migrateFriendUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_friend`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateGroupInfoUsingPost
   * @summary migrate group info data
   * @request POST:/server/admin/migration/migrate_group
   */
  migrateGroupInfoUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_group`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateGroupImageUsingPost
   * @summary migrate group image data for S3
   * @request POST:/server/admin/migration/migrate_group_image
   */
  migrateGroupImageUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_group_image`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateGroupMemberUsingPost
   * @summary migrate group member data
   * @request POST:/server/admin/migration/migrate_group_member
   */
  migrateGroupMemberUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_group_member`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateGroupResourceUsingPost
   * @summary migrate group static resource data
   * @request POST:/server/admin/migration/migrate_group_resource
   */
  migrateGroupResourceUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_group_resource`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateMothStudentUsingPost
   * @summary migrate month student data
   * @request POST:/server/admin/migration/migrate_month_student
   */
  migrateMothStudentUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_month_student`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateStaticResourcesUsingPost
   * @summary migrate wordpress static resources data
   * @request POST:/server/admin/migration/migrate_static_resource
   */
  migrateStaticResourcesUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_static_resource`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserAwardUsingPost
   * @summary migrate user impact point data
   * @request POST:/server/admin/migration/migrate_user_award
   */
  migrateUserAwardUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_user_award`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserInfoUsingPost
   * @summary migrate user info data
   * @request POST:/server/admin/migration/migrate_user_info
   */
  migrateUserInfoUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_user_info`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserMemberLevelUsingPost
   * @summary migrate user member level data
   * @request POST:/server/admin/migration/migrate_user_member_level
   */
  migrateUserMemberLevelUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_user_member_level`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserMetaExtendUsingPost
   * @summary migrate user profile data
   * @request POST:/server/admin/migration/migrate_user_meta_extend
   */
  migrateUserMetaExtendUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_user_meta_extend`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserPhotoUsingPost
   * @summary migrate user photo data
   * @request POST:/server/admin/migration/migrate_user_photo
   */
  migrateUserPhotoUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_user_photo`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigratePrivacySettingsUsingPost
   * @summary migrate user privacy Setting
   * @request POST:/server/admin/migration/migrate_user_privacy_setting
   */
  migratePrivacySettingsUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_user_privacy_setting`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUserResourceUsingPost
   * @summary migrate user static resource data
   * @request POST:/server/admin/migration/migrate_user_resource
   */
  migrateUserResourceUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/migrate_user_resource`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name ShowStaticResourcesUsingGet
   * @summary show wordpress static resources data
   * @request GET:/server/admin/migration/show_static_resource
   */
  showStaticResourcesUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/show_static_resource`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name TestBpMessageUsingGet
   * @summary test get wordpress message data
   * @request GET:/server/admin/migration/test_bp_message
   */
  testBpMessageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/test_bp_message`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name TestGroupMemberListUsingGet
   * @summary test get wordpress group member data
   * @request GET:/server/admin/migration/test_bp_message_member
   */
  testGroupMemberListUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/test_bp_message_member`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name TestBpMessageRecipientUsingGet
   * @summary test get wordpress wp_bp_messages_recipients data
   * @request GET:/server/admin/migration/test_bp_message_recipient
   */
  testBpMessageRecipientUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/test_bp_message_recipient`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name PageWpPostUsingGet
   * @summary 测试获取wp_post数据
   * @request GET:/server/admin/migration/test_post
   */
  pageWpPostUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** postType */
      postType?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/test_post`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name PageMetaWpPostUsingGet
   * @summary 测试获取wp_post_meta数据
   * @request GET:/server/admin/migration/test_post_meta
   */
  pageMetaWpPostUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** metaKey */
      metaKey?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/test_post_meta`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Migration Controller
   * @name MigrateUpdateFollowerNumberUsingPost
   * @summary update follower number
   * @request POST:/server/admin/migration/update_follower_number
   */
  migrateUpdateFollowerNumberUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/migration/update_follower_number`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Campaigns Controller
   * @name CreateCampaignsUsingPost
   * @summary Create Campaigns
   * @request POST:/server/admin/ms/campaigns/create
   */
  createCampaignsUsingPost = (
    createMsCampaignsDTO: CreateMsCampaignsDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/campaigns/create`,
      method: "POST",
      body: createMsCampaignsDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Campaigns Controller
   * @name CampaignsPageUsingGet
   * @summary Campaigns Info List
   * @request GET:/server/admin/ms/campaigns/list
   */
  campaignsPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/campaigns/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Campaigns Controller
   * @name RemoveCampaignsUsingDelete
   * @summary Remove Campaigns
   * @request DELETE:/server/admin/ms/campaigns/remove/{id}
   */
  removeCampaignsUsingDelete = (id: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/campaigns/remove/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Campaigns Controller
   * @name UpdateCampaignsUsingPut
   * @summary Update Campaigns Info
   * @request PUT:/server/admin/ms/campaigns/update/{id}
   */
  updateCampaignsUsingPut = (
    id: number,
    updateMsCampaignsDTO: UpdateMsCampaignsDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/campaigns/update/${id}`,
      method: "PUT",
      body: updateMsCampaignsDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Contracts Controller
   * @name ContractsPageUsingGet
   * @summary Contracts Info List
   * @request GET:/server/admin/ms/contracts/list
   */
  contractsPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** emailAddress */
      emailAddress?: string;
      /** firstName */
      firstName?: string;
      /**
       * lastDealStage
       * @format int64
       */
      lastDealStage?: number;
      /** lastName */
      lastName?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * status
       * @format int32
       */
      status?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/contracts/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Contracts Controller
   * @name RemoveContractsUsingDelete
   * @summary Remove Contracts
   * @request DELETE:/server/admin/ms/contracts/remove/{id}
   */
  removeContractsUsingDelete = (id: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/contracts/remove/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Contracts Controller
   * @name UpdateContractsUsingPut
   * @summary Update Contracts Info
   * @request PUT:/server/admin/ms/contracts/update/{id}
   */
  updateContractsUsingPut = (
    id: number,
    updateMsContactsDTO: UpdateMsContactsDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/contracts/update/${id}`,
      method: "PUT",
      body: updateMsContactsDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Deals Controller
   * @name DealsPageUsingGet
   * @summary Deals Info List
   * @request GET:/server/admin/ms/deals/list
   */
  dealsPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** emailAddress */
      emailAddress?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/deals/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Historical Subscriptions Controller
   * @name HistoricalSubscriptionsPageUsingGet
   * @summary Historical Subscriptions List
   * @request GET:/server/admin/ms/historical/subscriptions/list
   */
  historicalSubscriptionsPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** customerEmail */
      customerEmail?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/historical/subscriptions/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Historical Transactions Controller
   * @name HistoricalTransactionsPageUsingGet
   * @summary Historical Transactions List
   * @request GET:/server/admin/ms/historical/transactions/list
   */
  historicalTransactionsPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** customerEmail */
      customerEmail?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/historical/transactions/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS IPN MessageData Controller
   * @name MessagePageUsingGet
   * @summary Historical Transactions List
   * @request GET:/server/admin/ms/ipn/message/list
   */
  messagePageUsingGet = (
    query?: {
      /** buyerEmail */
      buyerEmail?: string;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/ipn/message/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Schedule Controller
   * @name ClearDealWorkerUsingPost
   * @summary Clear Deal Work
   * @request POST:/server/admin/ms/job/clear-deal-work
   */
  clearDealWorkerUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/job/clear-deal-work`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Schedule Controller
   * @name ClearIpnWorkUsingPost
   * @summary Clear IPN Work
   * @request POST:/server/admin/ms/job/clear-ipn-work
   */
  clearIpnWorkUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/job/clear-ipn-work`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Schedule Controller
   * @name ClearTransactionWorkerUsingPost
   * @summary Clear Transaction Work
   * @request POST:/server/admin/ms/job/clear-transaction-work
   */
  clearTransactionWorkerUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/job/clear-transaction-work`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Products Controller
   * @name CreateProductsUsingPost
   * @summary Create Products
   * @request POST:/server/admin/ms/products/create
   */
  createProductsUsingPost = (
    createProductsDTO: CreateProductsDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/products/create`,
      method: "POST",
      body: createProductsDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Products Controller
   * @name ProductsPageUsingGet
   * @summary Products Info List
   * @request GET:/server/admin/ms/products/list
   */
  productsPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** price */
      price?: number;
      /**
       * productId
       * @format int64
       */
      productId?: number;
      /** productName */
      productName?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/products/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Products Controller
   * @name UpdateProductsUsingPut
   * @summary Update Products Info
   * @request PUT:/server/admin/ms/products/update/{id}
   */
  updateProductsUsingPut = (
    id: number,
    updateProductsDTO: UpdateProductsDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/products/update/${id}`,
      method: "PUT",
      body: updateProductsDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS TransactionQueues Controller
   * @name TransactionQueuesPageUsingGet
   * @summary TransactionQueues List
   * @request GET:/server/admin/ms/queue/list
   */
  transactionQueuesPageUsingGet = (
    query?: {
      /**
       * contactId
       * @format int64
       */
      contactId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/queue/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin MS Subscriptions Controller
   * @name SubscriptionPageUsingGet
   * @summary Subscriptions Info List
   * @request GET:/server/admin/ms/subscriptions/list
   */
  subscriptionPageUsingGet = (
    query?: {
      /**
       * contactId
       * @format int64
       */
      contactId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/ms/subscriptions/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Notification Banner Controller
   * @name CreateBannerUsingPost
   * @summary create Notification Banner
   * @request POST:/server/admin/notification/banner/create
   */
  createBannerUsingPost = (
    dto: CreateNotificationBannerDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/notification/banner/create`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Notification Banner Controller
   * @name DeleteBannerUsingPost
   * @summary delete Notification Banner
   * @request POST:/server/admin/notification/banner/delete/{bannerId}
   */
  deleteBannerUsingPost = (bannerId: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/notification/banner/delete/${bannerId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Notification Banner Controller
   * @name GetEnableBannerUsingGet
   * @summary get enable Notification Banner
   * @request GET:/server/admin/notification/banner/getEnableBanner
   */
  getEnableBannerUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/notification/banner/getEnableBanner`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Notification Banner Controller
   * @name GetListUsingGet
   * @summary get Notification Banner list
   * @request GET:/server/admin/notification/banner/list
   */
  getListUsingGet = (
    query?: {
      /**
       * bannerId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      bannerId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** name */
      name?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/notification/banner/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Notification Banner Controller
   * @name UpdateBannerUsingPost
   * @summary update Notification Banner
   * @request POST:/server/admin/notification/banner/update
   */
  updateBannerUsingPost = (
    banner: NotificationBanner,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/notification/banner/update`,
      method: "POST",
      body: banner,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Page Controller
   * @name CreatePageUsingPost
   * @summary create page
   * @request POST:/server/admin/page/create
   */
  createPageUsingPost = (dto: CreateItuPageDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/page/create`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Page Controller
   * @name ShowPageListUsingGet
   * @summary show page list
   * @request GET:/server/admin/page/page
   */
  showPageListUsingGet = (
    query: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** pageType */
      pageType: "ZOOM_CAFE";
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/page/page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Page Controller
   * @name UpdatePageUsingPost
   * @summary update page
   * @request POST:/server/admin/page/update
   */
  updatePageUsingPost = (dto: UpdateItuPageDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/page/update`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Quick Link Controller
   * @name CreateEventUsingPost
   * @summary create quick link
   * @request POST:/server/admin/quick_link/create
   */
  createEventUsingPost = (
    dto: CreateQuickLinkDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/quick_link/create`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Quick Link Controller
   * @name DeleteEventUsingPost1
   * @summary delete quick link
   * @request POST:/server/admin/quick_link/delete
   */
  deleteEventUsingPost1 = (
    params: Record<string, string>,
    requestParams: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/quick_link/delete`,
      method: "POST",
      body: params,
      type: ContentType.Json,
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags ITU Admin Quick Link Controller
   * @name GetListUsingGet1
   * @summary Get quick link list
   * @request GET:/server/admin/quick_link/list
   */
  getListUsingGet1 = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** label */
      label?: string;
      /**
       * linkId
       * @format int64
       */
      linkId?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/quick_link/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Quick Link Controller
   * @name UpdateEventUsingPost1
   * @summary update quick link
   * @request POST:/server/admin/quick_link/update
   */
  updateEventUsingPost1 = (link: QuickLink, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/quick_link/update`,
      method: "POST",
      body: link,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Quick Link Controller
   * @name UpdateBatchLinkUsingPost
   * @summary update batch quick link
   * @request POST:/server/admin/quick_link/updateBatch
   */
  updateBatchLinkUsingPost = (
    dto: UpdateBatchQuickLinkDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/quick_link/updateBatch`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Report Controller
   * @name SummaryUsingGet
   * @summary Post Comments Like Summary
   * @request GET:/server/admin/report/summary
   */
  summaryUsingGet = (
    query: {
      /** category */
      category: string;
      /**
       * timeline
       * @format int32
       * @default 0
       */
      timeline?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/report/summary`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin S3 Store Controller
   * @name UploadFileUsingPost1
   * @summary Upload ClassWorksheets to private S3
   * @request POST:/server/admin/storage/uploadClassWorksheets
   */
  uploadFileUsingPost1 = (data: any, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/storage/uploadClassWorksheets`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin S3 Store Controller
   * @name UploadFileUsingPost
   * @summary Upload file's to S3
   * @request POST:/server/admin/storage/uploadFile
   */
  uploadFileUsingPost = (
    query: {
      /** resourceType */
      resourceType: "document" | "images" | "video";
    },
    data: {
      file?: File;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/storage/uploadFile`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Taxonomy Controller
   * @name CategoryUsingGet
   * @summary get category list
   * @request GET:/server/admin/term/taxonomy/category
   */
  categoryUsingGet = (
    query: {
      /** termTaxonomy */
      termTaxonomy:
        | "BpEmailType"
        | "BpGroupType"
        | "BpMemberType"
        | "BpmCategory"
        | "Category"
        | "EventSeriesTag"
        | "GamificationEarnedBy"
        | "GoalCategory"
        | "IdCourseCategory"
        | "IdCourseTag"
        | "IdGroupCategory"
        | "NavMenu"
        | "PostFormat"
        | "PostTag"
        | "TopicTag"
        | "TribeEventsCat"
        | "WpTheme";
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/term/taxonomy/category`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Taxonomy Controller
   * @name CreateCategoryUsingPost
   * @summary create category
   * @request POST:/server/admin/term/taxonomy/category/create
   */
  createCategoryUsingPost = (
    dto: CreateTaxonomyDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/term/taxonomy/category/create`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Taxonomy Controller
   * @name UpdateCategoryUsingPost
   * @summary update category
   * @request POST:/server/admin/term/taxonomy/category/update
   */
  updateCategoryUsingPost = (
    dto: UpdateTaxonomyDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/term/taxonomy/category/update`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Taxonomy Controller
   * @name CreateSeriesUsingPost
   * @summary create series
   * @request POST:/server/admin/term/taxonomy/series/create
   */
  createSeriesUsingPost = (dto: CreateSeriesDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/term/taxonomy/series/create`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Taxonomy Controller
   * @name DeleteSeriesUsingPost
   * @summary delete series
   * @request POST:/server/admin/term/taxonomy/series/delete
   */
  deleteSeriesUsingPost = (
    params: Record<string, string>,
    requestParams: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/term/taxonomy/series/delete`,
      method: "POST",
      body: params,
      type: ContentType.Json,
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags ITU Admin Taxonomy Controller
   * @name SeriesEventListUsingGet
   * @summary Get series event list
   * @request GET:/server/admin/term/taxonomy/series/event/list
   */
  seriesEventListUsingGet = (
    query: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * seriesId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      seriesId: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/term/taxonomy/series/event/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Taxonomy Controller
   * @name SeriesListUsingGet
   * @summary Get series list
   * @request GET:/server/admin/term/taxonomy/series/list
   */
  seriesListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * id
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      id?: number;
      /** name */
      name?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/term/taxonomy/series/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Taxonomy Controller
   * @name UpdateSeriesUsingPost
   * @summary update series
   * @request POST:/server/admin/term/taxonomy/series/update
   */
  updateSeriesUsingPost = (dto: UpdateSeriesDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/term/taxonomy/series/update`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Event Controller
   * @name CreateEventUsingPost1
   * @summary create event
   * @request POST:/server/admin/tribe_event/create
   */
  createEventUsingPost1 = (
    dto: CreateTribeEventDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/tribe_event/create`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Event Controller
   * @name DeleteEventUsingPost2
   * @summary delete event
   * @request POST:/server/admin/tribe_event/delete
   */
  deleteEventUsingPost2 = (
    params: Record<string, string>,
    requestParams: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/tribe_event/delete`,
      method: "POST",
      body: params,
      type: ContentType.Json,
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags ITU Admin Event Controller
   * @name GetListUsingGet2
   * @summary Get event list
   * @request GET:/server/admin/tribe_event/list
   */
  getListUsingGet2 = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * eventId
       * @format int64
       */
      eventId?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** title */
      title?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/tribe_event/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin Event Controller
   * @name UpdateEventUsingPost2
   * @summary update event
   * @request POST:/server/admin/tribe_event/update
   */
  updateEventUsingPost2 = (
    dto: UpdateTribeEventDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/tribe_event/update`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name FindAllUserUsingGet
   * @summary Administrator access to all user Information
   * @request GET:/server/admin/users/all_users
   */
  findAllUserUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** email */
      email?: string;
      /** firstName */
      firstName?: string;
      /** lastName */
      lastName?: string;
      /**
       * profileTypeId
       * @format int64
       */
      profileTypeId?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * sortAsc
       * @default false
       */
      sortAsc?: boolean;
      /** sortType */
      sortType?:
        | "CREATED_AT"
        | "DISPLAY_NAME"
        | "USER_EMAIL"
        | "USER_LOGIN"
        | "USER_NICK_NAME";
      /**
       * user_id
       * @format int64
       */
      user_id?: number;
      /** userNickName */
      userNickName?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/all_users`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name FindAllUserCompositeUsingGet
   * @summary get all user(fuzzy search first name, last name, nickname and email columns)
   * @request GET:/server/admin/users/all_users_like
   */
  findAllUserCompositeUsingGet = (
    query?: {
      /** fuzzy search first name, last name, nickname and email columns */
      compositeLike?: string;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * showMembership
       * @default true
       */
      showMembership?: boolean;
      /**
       * showProfile
       * @default true
       */
      showProfile?: boolean;
      /** showProfileItems */
      showProfileItems?: string;
      /**
       * showProfileName
       * @default true
       */
      showProfileName?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * sortAsc
       * @default false
       */
      sortAsc?: boolean;
      /** sortType */
      sortType?:
        | "CREATED_AT"
        | "DISPLAY_NAME"
        | "USER_EMAIL"
        | "USER_LOGIN"
        | "USER_NICK_NAME";
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/all_users_like`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name CreateMonthStudentUsingPost
   * @summary add the Student of the Month each month
   * @request POST:/server/admin/users/create_month_student
   */
  createMonthStudentUsingPost = (
    dto: AddMonthStudentDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/create_month_student`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name CreateNewUserUsingPost
   * @summary create user
   * @request POST:/server/admin/users/create_user
   */
  createNewUserUsingPost = (
    dto: AdminCreateUserDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/create_user`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name DeleteUserUsingPost
   * @summary Administrator delete one user
   * @request POST:/server/admin/users/delete_user
   */
  deleteUserUsingPost = (
    query: {
      /**
       * user_id
       * @format int64
       */
      user_id: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/delete_user`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name InitUserDataUsingPost
   * @summary Initialize user data,Restricted to dev environments only
   * @request POST:/server/admin/users/init_user_data
   */
  initUserDataUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/init_user_data`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name MonthStudentPageUsingGet
   * @summary the month student list
   * @request GET:/server/admin/users/month_student_page
   */
  monthStudentPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * mostMonth
       * @format int64
       */
      mostMonth?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/month_student_page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name NameSearchUsingGet
   * @summary Search User by name(firstName,LastName)
   * @request GET:/server/admin/users/name_search
   */
  nameSearchUsingGet = (
    query?: {
      /** compositeLike */
      compositeLike?: string;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 30
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/name_search`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name RemoveAccountHistoryUsingGet
   * @summary 删除账户记录
   * @request GET:/server/admin/users/remove/account/history
   */
  removeAccountHistoryUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** userEmail */
      userEmail?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/remove/account/history`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name RemoveAccountUsingDelete
   * @summary 删除账户
   * @request DELETE:/server/admin/users/remove/account/{userEmail}
   */
  removeAccountUsingDelete = (userEmail: string, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/remove/account/${userEmail}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name RemoveProfileTypeUsingPost
   * @summary delete profile type by id
   * @request POST:/server/admin/users/remove_profile_type/{id}
   */
  removeProfileTypeUsingPost = (id: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/remove_profile_type/${id}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name ResetPassUsingPost
   * @summary reset user password
   * @request POST:/server/admin/users/reset_pass
   */
  resetPassUsingPost = (dto: AdminResetPassDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/reset_pass`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name FindAllUserSimpleUsingGet
   * @summary show all user, not show profile and membership level
   * @request GET:/server/admin/users/select_box_user
   */
  findAllUserSimpleUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * profileTypeId
       * @format int64
       */
      profileTypeId?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** userNickName */
      userNickName?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/select_box_user`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name SendPasswordResetEmailByAdminUsingPost
   * @summary sendPasswordResetEmailByAdmin
   * @request POST:/server/admin/users/send_password_reset_email
   */
  sendPasswordResetEmailByAdminUsingPost = (
    dto: ForgotPasswordValidateCodeDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/send_password_reset_email`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name UpdateMonthStudentUsingPost
   * @summary update the Student of the Month each month
   * @request POST:/server/admin/users/update_month_student
   */
  updateMonthStudentUsingPost = (
    dto: UpdateMonthStudentDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/update_month_student`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name AddOrUpdateProfileTypeUsingPost
   * @summary add or modify profile type data
   * @request POST:/server/admin/users/update_profile_type
   */
  addOrUpdateProfileTypeUsingPost = (
    ituProfileType: ItuProfileType,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/update_profile_type`,
      method: "POST",
      body: ituProfileType,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Admin User Controller
   * @name UpdateUserUsingPost
   * @summary update user info
   * @request POST:/server/admin/users/update_user
   */
  updateUserUsingPost = (dto: AdminUpdateUserDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/users/update_user`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Video View Analytics Controller
   * @name VideoPlayCountRankUsingGet
   * @summary Play Count Top
   * @request GET:/server/admin/video/analytics/count_top
   */
  videoPlayCountRankUsingGet = (
    query: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** endDate */
      endDate: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** startDate */
      startDate: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/video/analytics/count_top`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Video View Analytics Controller
   * @name VideoPlayTimeRankUsingGet
   * @summary Play Time Top
   * @request GET:/server/admin/video/analytics/time_top
   */
  videoPlayTimeRankUsingGet = (
    query: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** endDate */
      endDate: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** startDate */
      startDate: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/video/analytics/time_top`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Video View Analytics Controller
   * @name VideoPlayHistoryUsingGet
   * @summary Video Play History
   * @request GET:/server/admin/video/analytics/video_play_history
   */
  videoPlayHistoryUsingGet = (
    query: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** endDate */
      endDate: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** startDate */
      startDate: string;
      /** videoUrl */
      videoUrl: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/video/analytics/video_play_history`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Video View Analytics Controller
   * @name VideoViewProgressUsingGet
   * @summary Video View Progress
   * @request GET:/server/admin/video/analytics/view_progress
   */
  videoViewProgressUsingGet = (
    query: {
      /**
       * viewId
       * @format int64
       */
      viewId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/admin/video/analytics/view_progress`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name BindAccountUsingPost
   * @summary Linking wallets and accounts
   * @request POST:/server/authorize/bind_account
   */
  bindAccountUsingPost = (
    bindAccountReqDTO: BindAccountReqDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/bind_account`,
      method: "POST",
      body: bindAccountReqDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name ChangePasswordUsingPost
   * @summary change password
   * @request POST:/server/authorize/change_password
   */
  changePasswordUsingPost = (
    changePassWordDTO: ChangePassWordDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/change_password`,
      method: "POST",
      body: changePassWordDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name GetForgotPassWordValidationCodeUsingGet
   * @summary get forgot validate code
   * @request GET:/server/authorize/get_forgot_validate_code
   */
  getForgotPassWordValidationCodeUsingGet = (
    query: {
      /** email */
      email: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/get_forgot_validate_code`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name LoginUsingPost
   * @summary User Login by userName or email
   * @request POST:/server/authorize/login
   */
  loginUsingPost = (
    loginParamReqDTO: LoginParamReqDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/login`,
      method: "POST",
      body: loginParamReqDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name LoginByWeb3UsingPost
   * @summary User login by web3
   * @request POST:/server/authorize/login_web3
   */
  loginByWeb3UsingPost = (
    signatureReqDTO: SignatureReqDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/login_web3`,
      method: "POST",
      body: signatureReqDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name LogoutUsingPost
   * @summary logout
   * @request POST:/server/authorize/logout
   */
  logoutUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/logout`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name NonceUsingPost
   * @summary Get Random signature information
   * @request POST:/server/authorize/nonce
   */
  nonceUsingPost = (nonceDTO: GetNonceDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/nonce`,
      method: "POST",
      body: nonceDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name RefreshJwtTokenUsingPost
   * @summary Refresh jwt token
   * @request POST:/server/authorize/refresh_jwt_token
   */
  refreshJwtTokenUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/refresh_jwt_token`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name RegisterUsingPost
   * @summary register by userName/password
   * @request POST:/server/authorize/register
   */
  registerUsingPost = (
    registerReqDTO: RegisterReqDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/register`,
      method: "POST",
      body: registerReqDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name RegisterWeb3UsingPost
   * @summary register by web3
   * @request POST:/server/authorize/register_web3
   */
  registerWeb3UsingPost = (
    dto: RegisterWeb3ReqDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/register_web3`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name SendForgotPassWordValidationCodeUsingPost
   * @summary send forgot validate code, Verification code is valid for two minutes
   * @request POST:/server/authorize/send_forgot_validate_code
   */
  sendForgotPassWordValidationCodeUsingPost = (
    forgotPasswordValidateCodeDTO: ForgotPasswordValidateCodeDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/send_forgot_validate_code`,
      method: "POST",
      body: forgotPasswordValidateCodeDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name Test500UsingGet
   * @summary test http 500 monitor
   * @request GET:/server/authorize/test500
   */
  test500UsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/test500`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Authorize Controller
   * @name GetUserIpUsingGet
   * @summary get user real ip, only use on dev/stag
   * @request GET:/server/authorize/user_ip
   */
  getUserIpUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/authorize/user_ip`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name CompleteChallengeUsingPost
   * @summary complete challenge
   * @request POST:/server/challenge/complete_challenge/{challengeId}
   * @deprecated
   */
  completeChallengeUsingPost = (
    challengeId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/complete_challenge/${challengeId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name CompleteTaskUsingPost
   * @summary complete task
   * @request POST:/server/challenge/complete_task/{challengeId}/{taskId}
   */
  completeTaskUsingPost = (
    challengeId: number,
    taskId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/complete_task/${challengeId}/${taskId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name CreateChallengeTeamMeetingUsingPost1
   * @summary create challenge team meeting
   * @request POST:/server/challenge/create_challenge_team_meeting
   */
  createChallengeTeamMeetingUsingPost1 = (
    dto: ChallengeTeamMeetingDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/create_challenge_team_meeting`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name CreateShareWorkUsingPost
   * @summary createShareWork
   * @request POST:/server/challenge/create_share_work
   */
  createShareWorkUsingPost = (
    dto: CreateShareWorkDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/create_share_work`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name CreateShareWorkRepliesUsingPost
   * @summary createShareWorkReplies
   * @request POST:/server/challenge/create_share_work_replies
   */
  createShareWorkRepliesUsingPost = (
    dto: CreateShareWorkRepliesDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/create_share_work_replies`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name ChallengeDetailUsingPost
   * @summary challenge detail
   * @request POST:/server/challenge/detail/{challengeId}
   */
  challengeDetailUsingPost = (
    challengeId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/detail/${challengeId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name JoinChallengeUsingPost
   * @summary join challenge
   * @request POST:/server/challenge/join_challenge/{challengeId}
   */
  joinChallengeUsingPost = (challengeId: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/join_challenge/${challengeId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name LeaderboardByChallengeUsingGet
   * @summary leaderboard_by_challenge
   * @request GET:/server/challenge/leaderboard_by_challenge/{challengeId}
   */
  leaderboardByChallengeUsingGet = (
    challengeId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/leaderboard_by_challenge/${challengeId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name ChallengeListUsingGet1
   * @summary challenge list
   * @request GET:/server/challenge/list
   */
  challengeListUsingGet1 = (
    query?: {
      /**
       * challengeId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      challengeId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** name */
      name?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name DiscussionListByChallengeIdUsingGet
   * @summary discussion list
   * @request GET:/server/challenge/list_discussion
   */
  discussionListByChallengeIdUsingGet = (
    query: {
      /**
       * challengeId
       * @format int64
       */
      challengeId: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/list_discussion`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name ShareWorkListUsingGet
   * @summary shareWorkList
   * @request GET:/server/challenge/list_share_work
   */
  shareWorkListUsingGet = (
    query: {
      /**
       * groupId
       * @format int64
       */
      groupId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/list_share_work`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name ShareWorkRepliesListUsingGet
   * @summary shareWorkRepliesList
   * @request GET:/server/challenge/list_share_work_replies
   */
  shareWorkRepliesListUsingGet = (
    query: {
      /**
       * shareWorkId
       * @format int64
       */
      shareWorkId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/list_share_work_replies`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name StartTaskUsingPost
   * @summary start task
   * @request POST:/server/challenge/start_task/{taskId}
   */
  startTaskUsingPost = (taskId: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/start_task/${taskId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name TaskDetailUsingGet
   * @summary get task detail
   * @request GET:/server/challenge/task_detail/{taskId}
   */
  taskDetailUsingGet = (taskId: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/task_detail/${taskId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name TaskListByChallengeUsingGet
   * @summary task_list_by_challenge
   * @request GET:/server/challenge/task_list_by_challenge/{challengeId}
   */
  taskListByChallengeUsingGet = (
    challengeId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/task_list_by_challenge/${challengeId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name TeamMeetingInfoUsingGet
   * @summary teamMeetingInfo
   * @request GET:/server/challenge/team_meeting_info/{teamId}
   */
  teamMeetingInfoUsingGet = (teamId: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/team_meeting_info/${teamId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name UpdateChallengeTeamMeetingUsingPost1
   * @summary update challenge team meeting
   * @request POST:/server/challenge/update_challenge_team_meeting
   */
  updateChallengeTeamMeetingUsingPost1 = (
    dto: ChallengeTeamMeetingDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/update_challenge_team_meeting`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name UpdateShareWorkUsingPost
   * @summary updateShareWork
   * @request POST:/server/challenge/update_share_work
   */
  updateShareWorkUsingPost = (
    works: ChallengeShareWorks,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/update_share_work`,
      method: "POST",
      body: works,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Challenge Controller
   * @name UploadFileChallengesUsingPost
   * @summary upload assignment and complete task
   * @request POST:/server/challenge/upload_assignment_and_complete_task/{challengeId}/{taskId}
   */
  uploadFileChallengesUsingPost = (
    challengeId: number,
    taskId: number,
    query: {
      /** resourceType */
      resourceType: "document" | "images" | "video";
    },
    data: {
      file?: File;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/challenge/upload_assignment_and_complete_task/${challengeId}/${taskId}`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Coaching replays Controller
   * @name GetImpact90ReplaysUsingGet
   * @summary getImpact90Replays
   * @request GET:/server/coaching/impact_90_replays
   */
  getImpact90ReplaysUsingGet = (
    query?: {
      /**
       * page
       * @format int32
       * @default 1
       */
      page?: number;
      /**
       * perPage
       * @format int32
       * @default 100
       */
      perPage?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/coaching/impact_90_replays`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Coaching replays Controller
   * @name GetNewFourVideoUsingGet
   * @summary getNewFourVideo
   * @request GET:/server/coaching/replays
   */
  getNewFourVideoUsingGet = (
    query?: {
      /**
       * page
       * @format int32
       * @default 1
       */
      page?: number;
      /**
       * perPage
       * @format int32
       * @default 4
       */
      perPage?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/coaching/replays`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name CompleteCoursesUsingPost
   * @summary complete_courses
   * @request POST:/server/courses/complete/courses/{coursesId}
   * @deprecated
   */
  completeCoursesUsingPost = (coursesId: string, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/complete/courses/${coursesId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name CompleteTopicUsingPost
   * @summary complete_topic
   * @request POST:/server/courses/complete/topic/{coursesId}/{topicId}
   */
  completeTopicUsingPost = (
    coursesId: number,
    topicId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/complete/topic/${coursesId}/${topicId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name CoursesListUsingGet1
   * @summary Get courses list
   * @request GET:/server/courses/courses_list
   */
  coursesListUsingGet1 = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** isBonus */
      isBonus?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/courses_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name CoursesListShowInPreviewUsingGet
   * @summary Get courses list
   * @request GET:/server/courses/courses_list_show_in_preview
   */
  coursesListShowInPreviewUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/courses_list_show_in_preview`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name CoursesWorksheetsUsingGet
   * @summary Get courses worksheets
   * @request GET:/server/courses/courses_worksheets
   */
  coursesWorksheetsUsingGet = (
    query?: {
      /**
       * coursesId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      coursesId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/courses_worksheets`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name GetCoursesDetailUsingGet
   * @summary Get courses detail
   * @request GET:/server/courses/detail/courses
   */
  getCoursesDetailUsingGet = (
    query: {
      /**
       * coursesId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      coursesId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/detail/courses`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name GetLessonDetailUsingGet
   * @summary Get lesson detail
   * @request GET:/server/courses/detail/lesson
   */
  getLessonDetailUsingGet = (
    query: {
      /**
       * coursesId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      coursesId: number;
      /**
       * lessonId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      lessonId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/detail/lesson`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name GetTopicDetailUsingGet
   * @summary Get topic detail
   * @request GET:/server/courses/detail/topic
   */
  getTopicDetailUsingGet = (
    query: {
      /**
       * coursesId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      coursesId: number;
      /**
       * topicId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      topicId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/detail/topic`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name DownloadCoursesMaterialsPdfUsingGet
   * @summary download_pdf
   * @request GET:/server/courses/download_pdf
   */
  downloadCoursesMaterialsPdfUsingGet = (
    query: {
      /**
       * materialsId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      materialsId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/server/courses/download_pdf`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name MyCoursesListUsingGet
   * @summary Get my courses list
   * @request GET:/server/courses/my_courses_list
   */
  myCoursesListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** isBonus */
      isBonus?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/my_courses_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name NextTopicUsingGet
   * @summary Get next topic id
   * @request GET:/server/courses/next_topic
   */
  nextTopicUsingGet = (
    query: {
      /**
       * coursesId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      coursesId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/next_topic`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name StartCoursesUsingPost
   * @summary start_courses
   * @request POST:/server/courses/start/courses/{coursesId}
   */
  startCoursesUsingPost = (coursesId: number, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/start/courses/${coursesId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name StartLessonUsingPost
   * @summary start_lesson
   * @request POST:/server/courses/start/lesson/{coursesId}/{lessonId}
   * @deprecated
   */
  startLessonUsingPost = (
    coursesId: number,
    lessonId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/start/lesson/${coursesId}/${lessonId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name TrackVideoUsingPost
   * @summary track_video
   * @request POST:/server/courses/track_video
   */
  trackVideoUsingPost = (dto: TrackVideoDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/track_video`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Courses Controller
   * @name UnCompleteTopicUsingPost
   * @summary unComplete_topic
   * @request POST:/server/courses/unComplete/topic/{coursesId}/{topicId}
   */
  unCompleteTopicUsingPost = (
    coursesId: number,
    topicId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/courses/unComplete/topic/${coursesId}/${topicId}`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Dict Data Controller
   * @name GetInterestsUsingGet
   * @summary Get Interests List
   * @request GET:/server/dict/interests
   */
  getInterestsUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/dict/interests`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name ChatListUsingGet
   * @summary get the chat list
   * @request GET:/server/direct_message/chat_list
   */
  chatListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** isRead */
      isRead?: boolean;
      /** searchMessage */
      searchMessage?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * sortType
       * @default "NEWEST"
       */
      sortType?: "NEWEST" | "OLDEST";
      /**
       * threadId
       * @format int64
       */
      threadId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/chat_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name ChatListByUserIdsUsingGet
   * @summary query chat_list by userId
   * @request GET:/server/direct_message/chat_list_users
   */
  chatListByUserIdsUsingGet = (
    query: {
      /** userIds */
      userIds: number[];
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/chat_list_users`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name ChatMessageListUsingGet
   * @summary get the chat content
   * @request GET:/server/direct_message/chat_message_list
   * @deprecated
   */
  chatMessageListUsingGet = (
    query: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** threadIds */
      threadIds: number[];
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/chat_message_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name ChatMessageListCacheUsingGet
   * @summary get the chat content by cache
   * @request GET:/server/direct_message/chat_message_list_cache
   */
  chatMessageListCacheUsingGet = (
    query: {
      /**
       * current
       * @format int64
       * @default 1
       */
      current?: number;
      /**
       * threadId
       * @format int64
       */
      threadId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/chat_message_list_cache`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name CreateNewDirectMessageUsingPost
   * @summary create an message
   * @request POST:/server/direct_message/create_message
   */
  createNewDirectMessageUsingPost = (
    dto: CreateNewMessageDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/create_message`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name CreateNewThreadUsingPost
   * @summary create an chat room
   * @request POST:/server/direct_message/create_thread
   */
  createNewThreadUsingPost = (
    dto: CreateDirectMessageDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/create_thread`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name EditDirectMessageUsingPost
   * @summary mark thread read
   * @request POST:/server/direct_message/edit_message
   */
  editDirectMessageUsingPost = (
    dto: EditMessageDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/edit_message`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name MarkThreadReadUsingPost
   * @summary mark thread read
   * @request POST:/server/direct_message/mark_thread_read
   */
  markThreadReadUsingPost = (
    dto: MarkThreadReadDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/mark_thread_read`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name RemoveMyMessageUsingPost
   * @summary remove my message
   * @request POST:/server/direct_message/remove_my_message
   */
  removeMyMessageUsingPost = (
    dto: DeleteOwnerMessageDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/remove_my_message`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name RemoveThreadMessageUsingPost
   * @summary delete thread
   * @request POST:/server/direct_message/remove_thread
   */
  removeThreadMessageUsingPost = (
    dto: DeleteThreadMessageDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/remove_thread`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Direct Message Controller
   * @name UnReadCountUsingGet
   * @summary Get the total number of unread messages from the user
   * @request GET:/server/direct_message/un_read_count
   */
  unReadCountUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/direct_message/un_read_count`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name AuditDiscussionUsingPost
   * @summary audit discussion by admin
   * @request POST:/server/discussions/audit_discussion
   */
  auditDiscussionUsingPost = (
    dto: AuditDiscussionDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/audit_discussion`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name AuditReplyUsingPost
   * @summary audit reply by admin
   * @request POST:/server/discussions/audit_reply
   */
  auditReplyUsingPost = (dto: AuditReplyDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/audit_reply`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name CreateDiscussionUsingPost
   * @summary create discussion
   * @request POST:/server/discussions/create_discussion
   */
  createDiscussionUsingPost = (
    dto: CreateDiscussionDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/create_discussion`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name CreateReplyUsingPost
   * @summary create reply
   * @request POST:/server/discussions/create_reply
   */
  createReplyUsingPost = (dto: CreateRepliesDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/create_reply`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name EditDiscussionUsingPost
   * @summary edit discussion
   * @request POST:/server/discussions/edit_discussion
   */
  editDiscussionUsingPost = (
    dto: EditDiscussionDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/edit_discussion`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name EditReplyUsingPost
   * @summary edit reply
   * @request POST:/server/discussions/edit_reply
   */
  editReplyUsingPost = (
    dto: EditDiscussionReplyDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/edit_reply`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name GetDiscussionUsingGet
   * @summary get discussion
   * @request GET:/server/discussions/get
   */
  getDiscussionUsingGet = (
    query: {
      /**
       * discussionId
       * @format int64
       */
      discussionId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/get`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name ListUsingGet
   * @summary discussion list
   * @request GET:/server/discussions/list_discussion
   */
  listUsingGet = (
    query: {
      /** compositeLike */
      compositeLike?: string;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * forumsId
       * @format int64
       */
      forumsId: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/list_discussion`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name PinDiscussionUsingPost
   * @summary pin discussion
   * @request POST:/server/discussions/pin_discussion
   */
  pinDiscussionUsingPost = (
    dto: PinDiscussionDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/pin_discussion`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name ReplyListUsingGet
   * @summary reply list
   * @request GET:/server/discussions/reply_list
   */
  replyListUsingGet = (
    query: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * discussionsId
       * @format int64
       * @min 1
       * @exclusiveMin false
       */
      discussionsId: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/reply_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Discussion Controller
   * @name UpdateDiscussionStickyUsingPost
   * @summary update discussion sticky
   * @request POST:/server/discussions/update_discussion_sticky
   * @deprecated
   */
  updateDiscussionStickyUsingPost = (
    dto: DiscussionStickyDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/discussions/update_discussion_sticky`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Favorite Topic Controller
   * @name SubscribeListUsingGet
   * @summary favorited list
   * @request GET:/server/favorite/favorited
   */
  subscribeListUsingGet = (
    query: {
      /**
       * current
       * @format int32
       */
      current: number;
      /** metaType */
      metaType: "DISCUSSION" | "FEED" | "FORUM";
      /**
       * page
       * @format int32
       */
      page: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/favorite/favorited`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Favorite Topic Controller
   * @name FavoriteTopicUsingPost
   * @summary user favorite forum|discussion|...
   * @request POST:/server/favorite/post
   */
  favoriteTopicUsingPost = (
    dto: TopicFavoriteDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/favorite/post`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Feed Message Controller
   * @name AuditActivityUsingPost
   * @summary audit activity
   * @request POST:/server/feed/audit_activity
   */
  auditActivityUsingPost = (
    dto: AuditActivityDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/feed/audit_activity`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Feed Message Controller
   * @name CommentListUsingGet
   * @summary comment list
   * @request GET:/server/feed/comment_list
   */
  commentListUsingGet = (
    query: {
      /**
       * activityId
       * @format int64
       */
      activityId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/feed/comment_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Feed Message Controller
   * @name CreatePostUsingPost
   * @summary create a post
   * @request POST:/server/feed/create_post
   */
  createPostUsingPost = (dto: PostCreateDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/feed/create_post`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Feed Message Controller
   * @name CreatePostCommentUsingPost
   * @summary create a post comment
   * @request POST:/server/feed/create_post_comment
   */
  createPostCommentUsingPost = (
    dto: PostCommentCreateDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/feed/create_post_comment`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Feed Message Controller
   * @name FeedListUsingGet
   * @summary feed list
   * @request GET:/server/feed/feed_list
   */
  feedListUsingGet = (
    query: {
      /** compositeLike */
      compositeLike?: string;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** feedType */
      feedType:
        | "ALL"
        | "FOLLOWING"
        | "GROUPS"
        | "INDEX_GROUPS"
        | "MENTIONS"
        | "PERSONAL";
      /**
       * groupId
       * @format int64
       */
      groupId?: number;
      /**
       * size
       * @format int32
       * @max 100
       * @exclusiveMax false
       * @default 10
       */
      size?: number;
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/feed/feed_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Feed Message Controller
   * @name LikeActivityUsingPost
   * @summary like someone activity
   * @request POST:/server/feed/like
   */
  likeActivityUsingPost = (dto: ActivityLikeDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/feed/like`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Feed Message Controller
   * @name SummaryOfUserPointsTestUsingPost
   * @summary summaryOfUserPointsTest
   * @request POST:/server/feed/summary_of_user_points/test
   */
  summaryOfUserPointsTestUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/feed/summary_of_user_points/test`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Feed Message Controller
   * @name UpdatePostUsingPut
   * @summary Update a post
   * @request PUT:/server/feed/update/post/{id}
   */
  updatePostUsingPut = (
    id: number,
    dto: PostUpdateDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/feed/update/post/${id}`,
      method: "PUT",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Feed Message Controller
   * @name UpdateActivityPinPostUsingPost
   * @summary update activity sticky or not
   * @request POST:/server/feed/update_post_pin
   */
  updateActivityPinPostUsingPost = (
    dto: PostPinDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/feed/update_post_pin`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Follower Controller
   * @name FollowedUsingPost
   * @summary Follow User
   * @request POST:/server/follower/followed
   */
  followedUsingPost = (dto: FollowUserDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/follower/followed`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Follower Controller
   * @name OwnerFollowerListUsingGet
   * @summary Get a list of my followers
   * @request GET:/server/follower/my_followers
   */
  ownerFollowerListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/follower/my_followers`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Follower Controller
   * @name OwnerConcernedListUsingGet
   * @summary Get a list of I follow
   * @request GET:/server/follower/my_leader
   */
  ownerConcernedListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * sortAsc
       * @default false
       */
      sortAsc?: boolean;
      /** sortType */
      sortType?: "CREATED_AT" | "DISPLAY_NAME";
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/follower/my_leader`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Forums Controller
   * @name CreateForumsUsingPost1
   * @summary create forums
   * @request POST:/server/forums/create_forums
   */
  createForumsUsingPost1 = (dto: CreateForumsDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/forums/create_forums`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Forums Controller
   * @name GetIntroductionsForumUsingGet
   * @summary Get Introductions forums
   * @request GET:/server/forums/forums_introductions
   */
  getIntroductionsForumUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/forums/forums_introductions`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Forums Controller
   * @name ForumsListUsingGet
   * @summary Get forums list by group
   * @request GET:/server/forums/forums_list
   */
  forumsListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * forumId
       * @format int64
       */
      forumId?: number;
      /** showVideo */
      showVideo?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/forums/forums_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Connect Friends Controller
   * @name CancelFriendRequestUsingPost
   * @summary Agree or reject friend requests
   * @request POST:/server/friends/deal_connect_request
   */
  cancelFriendRequestUsingPost = (
    dto: FriendAcceptOrCancelDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/friends/deal_connect_request`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Connect Friends Controller
   * @name DisconnectFriendUsingPost
   * @summary Disconnect with a connection
   * @request POST:/server/friends/disconnect
   */
  disconnectFriendUsingPost = (
    dto: DisconnectFriendRequestDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/friends/disconnect`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Connect Friends Controller
   * @name ListFriendsUsingGet
   * @summary get connect friend list
   * @request GET:/server/friends/friend_list
   */
  listFriendsUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * sortAsc
       * @default false
       */
      sortAsc?: boolean;
      /** sortType */
      sortType?:
        | "CREATED_AT"
        | "DISPLAY_NAME"
        | "USER_EMAIL"
        | "USER_LOGIN"
        | "USER_NICK_NAME";
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/friends/friend_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Connect Friends Controller
   * @name ListFriendsCountUsingGet
   * @summary get connect friend list count
   * @request GET:/server/friends/friend_list_count
   */
  listFriendsCountUsingGet = (
    query?: {
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/friends/friend_list_count`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Connect Friends Controller
   * @name IsFriendUsingGet
   * @summary Check if you are my friend
   * @request GET:/server/friends/isFriend
   */
  isFriendUsingGet = (
    query: {
      /**
       * userId
       * @format int64
       */
      userId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/friends/isFriend`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Connect Friends Controller
   * @name ListRequestUsingGet
   * @summary request a friend list
   * @request GET:/server/friends/request_list
   */
  listRequestUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * sortAsc
       * @default false
       */
      sortAsc?: boolean;
      /** sortType */
      sortType?:
        | "CREATED_AT"
        | "DISPLAY_NAME"
        | "USER_EMAIL"
        | "USER_LOGIN"
        | "USER_NICK_NAME";
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/friends/request_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Connect Friends Controller
   * @name CreateFriendRequestUsingPost
   * @summary send connect request
   * @request POST:/server/friends/send_connect_request
   */
  createFriendRequestUsingPost = (
    dto: FriendRequestDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/friends/send_connect_request`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Gamification Controller
   * @name ImpactPointLeaderboardUsingGet
   * @summary Impact Points Leaderboard
   * @request GET:/server/gamification/impact_point_leaderboard
   */
  impactPointLeaderboardUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 25
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/gamification/impact_point_leaderboard`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Gamification Controller
   * @name ImpactPointUserRankUsingGet
   * @summary User Rank in Impact Points
   * @request GET:/server/gamification/impact_point_user_rank
   */
  impactPointUserRankUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/gamification/impact_point_user_rank`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Goal Controller
   * @name AddOrUpdateGoalUsingPost
   * @summary Add or Update Goal
   * @request POST:/server/goal/add_update_goal
   */
  addOrUpdateGoalUsingPost = (
    dto: AddUpdateGoalDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/goal/add_update_goal`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Goal Controller
   * @name ChangeStepStatusUsingPost
   * @summary change goal step status
   * @request POST:/server/goal/change_goal_step_status
   */
  changeStepStatusUsingPost = (
    dto: ChangeStepStatusDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/goal/change_goal_step_status`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Goal Controller
   * @name RemoveGoalUsingPost
   * @summary delete goal
   * @request POST:/server/goal/delete_goal
   */
  removeGoalUsingPost = (dto: RemoveGoalDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/goal/delete_goal`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Goal Controller
   * @name RemoveGoalStepUsingPost
   * @summary delete goal step
   * @request POST:/server/goal/delete_goal_step
   */
  removeGoalStepUsingPost = (
    dto: RemoveGoalStepDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/goal/delete_goal_step`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Goal Controller
   * @name GoalListUsingGet1
   * @summary User Goals List
   * @request GET:/server/goal/user_goal
   */
  goalListUsingGet1 = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * goalId
       * @format int64
       */
      goalId?: number;
      /** goalName */
      goalName?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/goal/user_goal`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name AcceptInviteJoinGroupUsingPost
   * @summary Students agree to join the group Or Organizers agreed student join the group
   * @request POST:/server/groups/accept_invite_group
   */
  acceptInviteJoinGroupUsingPost = (
    dto: UserAcceptJoinGroupDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/accept_invite_group`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name ChangeMemberTypeUsingPost
   * @summary Modify the status of a member in a group
   * @request POST:/server/groups/change_member_type
   */
  changeMemberTypeUsingPost = (
    groupMemberTypeChangeDTO: GroupMemberTypeChangeDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/change_member_type`,
      method: "POST",
      body: groupMemberTypeChangeDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name CheckSendJoinRequestUsingGet
   * @summary Check if a request to join the group has been sent
   * @request GET:/server/groups/check_send_join_request
   */
  checkSendJoinRequestUsingGet = (
    query: {
      /**
       * groupId
       * @format int64
       */
      groupId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/check_send_join_request`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name CreateGroupUsingPost1
   * @summary create group
   * @request POST:/server/groups/create_group
   * @deprecated
   */
  createGroupUsingPost1 = (
    createGroupDTO: CreateGroupDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/create_group`,
      method: "POST",
      body: createGroupDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name CreateGroupAlbumUsingPost
   * @summary create group album
   * @request POST:/server/groups/create_group_album
   */
  createGroupAlbumUsingPost = (
    dto: CreateGroupAlbumDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/create_group_album`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name CreateGroupResourceMultipleUsingPost
   * @summary create group resource Multiple
   * @request POST:/server/groups/create_group_resource_multiple
   */
  createGroupResourceMultipleUsingPost = (
    dto: CreateGroupMultipleDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/create_group_resource_multiple`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name GeneralFeedLeftUsingGet
   * @summary general feed left
   * @request GET:/server/groups/general_feed_left
   */
  generalFeedLeftUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/general_feed_left`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name GetGroupZoomConfigUsingGet
   * @summary get group config
   * @request GET:/server/groups/get_group_zoom_config
   */
  getGroupZoomConfigUsingGet = (
    query: {
      /**
       * groupId
       * @format int64
       */
      groupId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/get_group_zoom_config`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name GroupMembersUsingGet
   * @summary get group member
   * @request GET:/server/groups/group_members
   */
  groupMembersUsingGet = (
    query: {
      /** compositeLike */
      compositeLike?: string;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * groupId
       * @format int64
       */
      groupId: number;
      /**
       * showFollow
       * @default false
       */
      showFollow?: boolean;
      /**
       * showRank
       * @default false
       */
      showRank?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/group_members`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name ListGroupTypeUsingGet
   * @summary get group type list
   * @request GET:/server/groups/group_type_list
   */
  listGroupTypeUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/group_type_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name InviteJoinGroupUsingPost
   * @summary Organizers invite student to join the group
   * @request POST:/server/groups/invite_join_group
   */
  inviteJoinGroupUsingPost = (
    dto: RequestInvitationDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/invite_join_group`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name JoinGroupUsingPost
   * @summary join group
   * @request POST:/server/groups/join_group
   */
  joinGroupUsingPost = (
    joinGroupDTO: JoinGroupDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/join_group`,
      method: "POST",
      body: joinGroupDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name LeaveGroupUsingPost
   * @summary Leave group
   * @request POST:/server/groups/leave_group
   */
  leaveGroupUsingPost = (
    leaveGroupDTO: LeaveGroupDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/leave_group`,
      method: "POST",
      body: leaveGroupDTO,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name ListGroupUsingGet1
   * @summary get information about group
   * @request GET:/server/groups/list
   */
  listGroupUsingGet1 = (
    query?: {
      /**
       * challengeId
       * @format int64
       */
      challengeId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * groupId
       * @format int64
       */
      groupId?: number;
      /** groupName */
      groupName?: string;
      /** groupPrivacy */
      groupPrivacy?: "HIDDEN" | "PASSWORD_PROTECTED" | "PRIVATE" | "PUBLIC";
      /**
       * groupTypeId
       * @format int64
       */
      groupTypeId?: number;
      /** showMember */
      showMember?: boolean;
      /** showMemberCount */
      showMemberCount?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * sortAsc
       * @default false
       */
      sortAsc?: boolean;
      /** sortType */
      sortType?: "ACTIVE_TIME" | "CREATED_AT" | "GROUP_NAME";
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name OrganizersGetJoinGroupInviteListUsingGet
   * @summary organizers get join group invite  list
   * @request GET:/server/groups/org_get_join_invite_list
   */
  organizersGetJoinGroupInviteListUsingGet = (
    query: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * groupId
       * @format int64
       */
      groupId: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/org_get_join_invite_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name OrganizersGetJoinGroupRequestListUsingGet
   * @summary organizers get join group request list
   * @request GET:/server/groups/org_get_join_request_list
   */
  organizersGetJoinGroupRequestListUsingGet = (
    query: {
      /**
       * accepted
       * @format int64
       */
      accepted?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * groupId
       * @format int64
       */
      groupId: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/org_get_join_request_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name OrganizerAcceptJoinGroupRequestUsingPost
   * @summary organizer accept join group
   * @request POST:/server/groups/organizer_accept_join_group
   */
  organizerAcceptJoinGroupRequestUsingPost = (
    dto: AcceptJoinGroupDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/organizer_accept_join_group`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name OrganizerRejectedJoinGroupRequestUsingPost
   * @summary organizer rejected join group
   * @request POST:/server/groups/organizer_rejected_join_group
   */
  organizerRejectedJoinGroupRequestUsingPost = (
    dto: OrganizerRejectedJoinGroupDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/organizer_rejected_join_group`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name OwnerListChallengeGroupUsingGet
   * @summary owner challenge group list
   * @request GET:/server/groups/owner_challenge_group_list
   */
  ownerListChallengeGroupUsingGet = (
    query: {
      /**
       * challengeId
       * @format int64
       */
      challengeId: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/owner_challenge_group_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name OwnerListGroupUsingGet
   * @summary owner group list
   * @request GET:/server/groups/owner_group_list
   */
  ownerListGroupUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/owner_group_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name OwnerListGroupCountUsingGet
   * @summary owner group list count
   * @request GET:/server/groups/owner_group_list_count
   */
  ownerListGroupCountUsingGet = (
    query?: {
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/owner_group_list_count`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name RejectedInviteJoinGroupUsingPost
   * @summary user rejected invite join group
   * @request POST:/server/groups/rejected_invite_group
   */
  rejectedInviteJoinGroupUsingPost = (
    dto: RejectedJoinGroupDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/rejected_invite_group`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name RemoveGroupAlbumUsingPost
   * @summary remove group Album
   * @request POST:/server/groups/remove_group_album
   */
  removeGroupAlbumUsingPost = (
    dto: RemoveGroupAlbumDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/remove_group_album`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name RemoveGroupResourceUsingPost
   * @summary remove group resource
   * @request POST:/server/groups/remove_group_resource
   */
  removeGroupResourceUsingPost = (
    dto: RemoveGroupResourceDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/remove_group_resource`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name RequestJoinGroupUsingPost
   * @summary Students apply to join the group
   * @request POST:/server/groups/request_join_group
   */
  requestJoinGroupUsingPost = (
    dto: UserRequestInvitationDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/request_join_group`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name SendGroupMessageUsingPost
   * @summary Organizers send message to all member
   * @request POST:/server/groups/send_group_message
   */
  sendGroupMessageUsingPost = (
    dto: SendGroupMessageDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/send_group_message`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name ShowGroupResourceAlbumUsingGet
   * @summary show group resource album
   * @request GET:/server/groups/show_group_album
   */
  showGroupResourceAlbumUsingGet = (
    query: {
      /**
       * albumId
       * @format int64
       */
      albumId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * groupId
       * @format int64
       */
      groupId: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/show_group_album`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name ShowGroupResourceUsingGet
   * @summary show group resource
   * @request GET:/server/groups/show_group_resource
   */
  showGroupResourceUsingGet = (
    query: {
      /**
       * albumId
       * @format int64
       */
      albumId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * groupId
       * @format int64
       */
      groupId: number;
      /** privacy */
      privacy?: "ALL_MEMBERS" | "MY_CONNECTIONS" | "ONLY_ME" | "PUBLIC";
      /** resourceType */
      resourceType?: "DOCUMENTS" | "PHOTO" | "VIDEO";
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/show_group_resource`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name UpdateGroupDetailUsingPost
   * @summary update group detail
   * @request POST:/server/groups/update_group_detail
   */
  updateGroupDetailUsingPost = (
    dto: UpdateGroupDetailDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/update_group_detail`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name UpdateOpenForumStatusUsingPost
   * @summary set group forum open or close
   * @request POST:/server/groups/update_group_forum_status
   */
  updateOpenForumStatusUsingPost = (
    dto: OpenForumStatusDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/update_group_forum_status`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name UpdateGroupCoverPictureUsingPost
   * @summary update group Picture(only Organizer can do it)
   * @request POST:/server/groups/update_group_picture
   */
  updateGroupCoverPictureUsingPost = (
    dto: UpdateGroupCoverPictureDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/update_group_picture`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name UpdateGroupSettingUsingPost
   * @summary update group setting(only Organizer can do it)
   * @request POST:/server/groups/update_group_setting
   */
  updateGroupSettingUsingPost = (
    dto: UpdateGroupSettingDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/update_group_setting`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name UpdateSettingZoomUsingPost
   * @summary set group zoom config
   * @request POST:/server/groups/update_group_zoom_config
   */
  updateSettingZoomUsingPost = (
    dto: UpdateGroupZoomDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/update_group_zoom_config`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Group Controller
   * @name UserGetJoinGroupInviteListUsingGet
   * @summary user get join group invite list
   * @request GET:/server/groups/user_get_join_invite_list
   */
  userGetJoinGroupInviteListUsingGet = (
    query?: {
      /**
       * accepted
       * @format int64
       */
      accepted?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/groups/user_get_join_invite_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Journal Controller
   * @name ShowDailyJournalUsingGet
   * @summary show daily journal
   * @request GET:/server/journal/daily_journal
   */
  showDailyJournalUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/journal/daily_journal`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Journal Controller
   * @name CreateJournalUsingPost
   * @summary create journal
   * @request POST:/server/journal/new
   */
  createJournalUsingPost = (
    dto: CreateJournalEntryDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/journal/new`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Journal Controller
   * @name ShowDailyPromptUsingGet
   * @summary show daily prompt
   * @request GET:/server/journal/show_daily_prompt
   */
  showDailyPromptUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/journal/show_daily_prompt`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Journal Controller
   * @name ShowRandomPromptUsingGet
   * @summary show random prompt
   * @request GET:/server/journal/show_random_prompt
   */
  showRandomPromptUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/journal/show_random_prompt`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Journal Controller
   * @name UpdateJournalUsingPost
   * @summary update journal
   * @request POST:/server/journal/update
   */
  updateJournalUsingPost = (
    dto: UpdateJournalEntryDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/journal/update`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Membership Controller
   * @name MembershipLevelPageUsingGet1
   * @summary membership level info list
   * @request GET:/server/membership/level
   */
  membershipLevelPageUsingGet1 = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/membership/level`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Notice Controller
   * @name ConfigEnumUsingGet
   * @summary notification config enum
   * @request GET:/server/notice/config_enum
   */
  configEnumUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/notice/config_enum`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Notice Controller
   * @name NewNoticeCountUsingGet
   * @summary Get the user's unread messages count
   * @request GET:/server/notice/new_notice_count
   */
  newNoticeCountUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/notice/new_notice_count`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Notice Controller
   * @name NewNoticeListUsingGet
   * @summary Get the user's unread messages
   * @request GET:/server/notice/new_notice_list
   */
  newNoticeListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * news
       * @default true
       */
      news?: boolean;
      /** noticeKey */
      noticeKey?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/notice/new_notice_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Notice Controller
   * @name RemoveAllNoticeUsingPost
   * @summary Remove All notice
   * @request POST:/server/notice/remove_all_notice
   */
  removeAllNoticeUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/notice/remove_all_notice`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Notice Controller
   * @name RemoveNoticeUsingPost
   * @summary Remove notice
   * @request POST:/server/notice/remove_notice
   */
  removeNoticeUsingPost = (dto: CommonIdsDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/notice/remove_notice`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Notice Controller
   * @name SetAllNoticeReadUsingPost
   * @summary Set all notice read
   * @request POST:/server/notice/set_all_notice_read
   */
  setAllNoticeReadUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/notice/set_all_notice_read`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Notice Controller
   * @name SetNoticeReadUsingPost
   * @summary Set notice read
   * @request POST:/server/notice/set_notice_read
   */
  setNoticeReadUsingPost = (
    dto: SetNoticeReadDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/notice/set_notice_read`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Page Controller
   * @name ShowPageListUsingGet1
   * @summary show page list
   * @request GET:/server/page/show_page
   */
  showPageListUsingGet1 = (
    query: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** pageType */
      pageType: "ZOOM_CAFE";
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/page/show_page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Rest S3 Controller
   * @name UploadFileUsingPost2
   * @summary Upload file's to S3
   * @request POST:/server/storage/uploadFile
   */
  uploadFileUsingPost2 = (
    query: {
      /**
       * archive
       * @default true
       */
      archive?: boolean;
      /** resourceType */
      resourceType: "document" | "images" | "video";
    },
    data: {
      file?: File;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/storage/uploadFile`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Rest S3 Controller
   * @name UploadFileChallengesUsingPost1
   * @summary Upload file's to S3
   * @request POST:/server/storage/uploadFile/challenges
   */
  uploadFileChallengesUsingPost1 = (
    query: {
      /** resourceType */
      resourceType: "document" | "images" | "video";
    },
    data: {
      file?: File;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/storage/uploadFile/challenges`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU System Controller
   * @name SelectHeroSectionsUsingGet1
   * @summary Get hero_section list
   * @request GET:/server/sys/hero_section/list
   */
  selectHeroSectionsUsingGet1 = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * isActive
       * @default true
       */
      isActive?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** title */
      title?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/sys/hero_section/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU System Controller
   * @name HubspotEnvironmentUsingGet
   * @summary Get Hubspot config
   * @request GET:/server/sys/hubspot/config
   */
  hubspotEnvironmentUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/sys/hubspot/config`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU System Controller
   * @name GetEnableBannerUsingGet1
   * @summary get enable Notification Banner
   * @request GET:/server/sys/notification/banner/getEnableBanner
   */
  getEnableBannerUsingGet1 = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/sys/notification/banner/getEnableBanner`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU System Controller
   * @name GetListUsingGet3
   * @summary Get quick link list
   * @request GET:/server/sys/quick_link/list
   */
  getListUsingGet3 = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** label */
      label?: string;
      /**
       * linkId
       * @format int64
       */
      linkId?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/sys/quick_link/list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Taxonomy Controller
   * @name EventListUsingGet
   * @summary Get event taxonomy list
   * @request GET:/server/term/taxonomy/event/category/list
   */
  eventListUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/term/taxonomy/event/category/list`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Taxonomy Controller
   * @name GoalListUsingGet2
   * @summary Get goal category list
   * @request GET:/server/term/taxonomy/goal/category
   */
  goalListUsingGet2 = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/term/taxonomy/goal/category`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Timeline Controller
   * @name CreateTimelineUsingPost
   * @summary create timeline
   * @request POST:/server/timeline/create
   */
  createTimelineUsingPost = (
    dto: CreateTimelineDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/timeline/create`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Timeline Controller
   * @name DeleteTimelineUsingPost
   * @summary delete timeline
   * @request POST:/server/timeline/delete
   */
  deleteTimelineUsingPost = (dto: TimelineIdDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/timeline/delete`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Timeline Controller
   * @name LikeTimelineUsingPost
   * @summary like timeline
   * @request POST:/server/timeline/like
   */
  likeTimelineUsingPost = (dto: TimelineIdDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/timeline/like`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Timeline Controller
   * @name GoalListUsingGet3
   * @summary Get timeline list
   * @request GET:/server/timeline/list
   */
  goalListUsingGet3 = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/timeline/list`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Timeline Controller
   * @name PinTimelineUsingPost
   * @summary pin timeline
   * @request POST:/server/timeline/pin
   */
  pinTimelineUsingPost = (dto: TimelineIdDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/timeline/pin`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Timeline Controller
   * @name UnlikeTimelineUsingPost
   * @summary unlike timeline
   * @request POST:/server/timeline/unlike
   */
  unlikeTimelineUsingPost = (dto: TimelineIdDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/timeline/unlike`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Timeline Controller
   * @name UnPinTimelineUsingPost
   * @summary unpin timeline
   * @request POST:/server/timeline/unpin
   */
  unPinTimelineUsingPost = (dto: TimelineIdDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/timeline/unpin`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Event Controller
   * @name ExportUsingGet
   * @summary Get event detail
   * @request GET:/server/tribe_event/calendar_export
   */
  exportUsingGet = (
    query?: {
      /**
       * month
       * @default "2023-07"
       */
      month?: string;
      /** title */
      title?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Resource, any>({
      path: `/server/tribe_event/calendar_export`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Event Controller
   * @name GetCalendarListUsingGet
   * @summary Get calendar list
   * @request GET:/server/tribe_event/calendar_list
   */
  getCalendarListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** 2023-07-10 */
      day?: string;
      /** 2023-07-10 23:59:59 */
      endDate?: string;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** 2023-07-10 00:00:00 */
      startDate?: string;
      /** title */
      title?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/tribe_event/calendar_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Event Controller
   * @name GetCalendarListUsingGet1
   * @summary Get calendar list by day
   * @request GET:/server/tribe_event/calendar_list_by_day
   */
  getCalendarListUsingGet1 = (
    query: {
      /** 2023-07-10 */
      day: string;
      /** title */
      title?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/tribe_event/calendar_list_by_day`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Event Controller
   * @name GetEventListByMonthUsingGet
   * @summary Get calendar list
   * @request GET:/server/tribe_event/calendar_list_by_month
   */
  getEventListByMonthUsingGet = (
    query?: {
      /**
       * month
       * @default "2023-07"
       */
      month?: string;
      /** title */
      title?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/tribe_event/calendar_list_by_month`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Event Controller
   * @name EventDetailUsingGet
   * @summary Get event detail
   * @request GET:/server/tribe_event/detail
   */
  eventDetailUsingGet = (
    query: {
      /**
       * id
       * @format int32
       */
      id: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/tribe_event/detail`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU Event Controller
   * @name RecentCalendarListUsingGet
   * @summary Get calendar list
   * @request GET:/server/tribe_event/recent_calendar_list
   */
  recentCalendarListUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/tribe_event/recent_calendar_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Subscribe Controller
   * @name CreateUsingPost
   * @summary Create subscribe
   * @request POST:/server/user/subscribe/create
   */
  createUsingPost = (request: UserSubscribeDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/user/subscribe/create`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Subscribe Controller
   * @name RemoveUsingDelete
   * @summary Cancel subscribe
   * @request DELETE:/server/user/subscribe/remove
   */
  removeUsingDelete = (request: UserSubscribeDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/user/subscribe/remove`,
      method: "DELETE",
      body: request,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name CreateAlbumUsingPost
   * @summary create album
   * @request POST:/server/users/create_user_album
   */
  createAlbumUsingPost = (
    dto: CreateUserAlbumDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/create_user_album`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name CreateUserResourceUsingPost
   * @summary create user resource(video/documents)
   * @request POST:/server/users/create_user_resource
   */
  createUserResourceUsingPost = (
    dto: CreateUserResourceDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/create_user_resource`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name CreateUserMultipleVideoUsingPost
   * @summary create user resource Multiple
   * @request POST:/server/users/create_user_resource_multiple
   */
  createUserMultipleVideoUsingPost = (
    dto: CreateUserVideoMultipleDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/create_user_resource_multiple`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name FollowApproveUsingPost
   * @summary approve follow
   * @request POST:/server/users/follow/approve
   * @deprecated
   */
  followApproveUsingPost = (
    dto: FollowApproveDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/follow/approve`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name FollowAskUsingPost
   * @summary request to follow
   * @request POST:/server/users/follow/ask
   * @deprecated
   */
  followAskUsingPost = (dto: FollowAskDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/follow/ask`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name FollowListUsingPost
   * @summary get follow list
   * @request POST:/server/users/follow/list
   * @deprecated
   */
  followListUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/follow/list`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name MonthStudentPageUsingGet1
   * @summary the month student list
   * @request GET:/server/users/month_student_page
   */
  monthStudentPageUsingGet1 = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * mostMonth
       * @format int64
       */
      mostMonth?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/month_student_page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name MoveResourceToAlbumUsingPost
   * @summary move resource to album
   * @request POST:/server/users/move_resource_album
   */
  moveResourceToAlbumUsingPost = (
    dto: MoveResourceToAlbumDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/move_resource_album`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name NameSearchUsingGet1
   * @summary Search User by name(firstName,LastName)
   * @request GET:/server/users/name_search
   */
  nameSearchUsingGet1 = (
    query: {
      /** compositeLike */
      compositeLike: string;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 30
       */
      size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/name_search`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name SetNewPasswordUsingPost
   * @summary set new password
   * @request POST:/server/users/new_password
   */
  setNewPasswordUsingPost = (dto: NewPassWordDTO, params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/new_password`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ProfileTypeListUsingGet
   * @summary profile type list
   * @request GET:/server/users/profile_type
   */
  profileTypeListUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/profile_type`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name RemoveOwnerAlbumUsingPost
   * @summary remove owner album
   * @request POST:/server/users/remove_owner_album
   */
  removeOwnerAlbumUsingPost = (
    dto: RemoveOwnerAlbumDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/remove_owner_album`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name RemoveUserResourceUsingPost
   * @summary remove resource
   * @request POST:/server/users/remove_user_resource
   */
  removeUserResourceUsingPost = (
    dto: RemoveUserResourceDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/remove_user_resource`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name UpdateVisitedHomePageUsingPost
   * @summary Set the home page that has been visited
   * @request POST:/server/users/set_visited_home_page
   */
  updateVisitedHomePageUsingPost = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/set_visited_home_page`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ShowUserInfoUsingGet
   * @summary Get base information about a user, Also get profile information
   * @request GET:/server/users/show_info
   */
  showUserInfoUsingGet = (
    query: {
      /**
       * showReward
       * @default false
       */
      showReward?: boolean;
      /**
       * user_id
       * @format int64
       */
      user_id: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/show_info`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ShowOwnerResourceAlbumUsingGet
   * @summary show owner resource album
   * @request GET:/server/users/show_owner_album
   */
  showOwnerResourceAlbumUsingGet = (
    query?: {
      /**
       * albumId
       * @format int64
       */
      albumId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/show_owner_album`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ShowOwnerUserInfoUsingGet
   * @summary Get base information about owner
   * @request GET:/server/users/show_owner_info
   */
  showOwnerUserInfoUsingGet = (
    query?: {
      /**
       * showReward
       * @default false
       */
      showReward?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/show_owner_info`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ShowOwnerMembershipUsingGet
   * @summary show owner membership
   * @request GET:/server/users/show_owner_membership
   */
  showOwnerMembershipUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/show_owner_membership`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ShowOwnerUserProfileUsingGet
   * @summary Get profile information about owner
   * @request GET:/server/users/show_owner_profile
   */
  showOwnerUserProfileUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/show_owner_profile`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ShowOwnerResourceUsingGet
   * @summary show owner resource
   * @request GET:/server/users/show_owner_resource
   */
  showOwnerResourceUsingGet = (
    query?: {
      /**
       * albumId
       * @format int64
       */
      albumId?: number;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** privacy */
      privacy?: "ALL_MEMBERS" | "MY_CONNECTIONS" | "ONLY_ME" | "PUBLIC";
      /** resourceType */
      resourceType?: "DOCUMENTS" | "PHOTO" | "VIDEO";
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/show_owner_resource`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ShowOwnerResourceCountUsingGet
   * @summary show owner resource count
   * @request GET:/server/users/show_owner_resource_count
   */
  showOwnerResourceCountUsingGet = (
    query?: {
      /** resourceType */
      resourceType?: "DOCUMENTS" | "PHOTO" | "VIDEO";
      /**
       * userId
       * @format int64
       */
      userId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/show_owner_resource_count`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ShowUserProfileUsingGet
   * @summary Get profile information about a user
   * @request GET:/server/users/show_user_profile
   */
  showUserProfileUsingGet = (
    query: {
      /**
       * user_id
       * @format int64
       */
      user_id: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/show_user_profile`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name UpdateUserBaseInfoUsingPost
   * @summary Modify user base information
   * @request POST:/server/users/update_base
   */
  updateUserBaseInfoUsingPost = (
    dto: UserBaseInfoDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/update_base`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name UpdateUserProfileUsingPost
   * @summary Modify user extension information
   * @request POST:/server/users/update_profile
   */
  updateUserProfileUsingPost = (
    userMetaVo: UserMetaVo,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/update_profile`,
      method: "POST",
      body: userMetaVo,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ShowUserPageUsingGet
   * @summary Get a list of all user
   * @request GET:/server/users/user_list
   */
  showUserPageUsingGet = (
    query?: {
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** firstName */
      firstName?: string;
      /** goals */
      goals?: string;
      /** hobbies */
      hobbies?: string;
      /** interests */
      interests?: string;
      /** joinWhy */
      joinWhy?: string;
      /** lastName */
      lastName?: string;
      /**
       * profileTypeId
       * @format int64
       */
      profileTypeId?: number;
      /**
       * showMemberShip
       * @default false
       */
      showMemberShip?: boolean;
      /**
       * showProfile
       * @default false
       */
      showProfile?: boolean;
      /**
       * showReward
       * @default false
       */
      showReward?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /** userNickname */
      userNickname?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/user_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name ShowUserPageV2UsingGet
   * @summary Get a list of all user v2
   * @request GET:/server/users/v2/user_list
   */
  showUserPageV2UsingGet = (
    query?: {
      /** compositeLike */
      compositeLike?: string;
      /**
       * current
       * @format int32
       * @default 1
       */
      current?: number;
      /** interests */
      interests?: string;
      /**
       * profileTypeId
       * @format int64
       */
      profileTypeId?: number;
      /**
       * showMemberShip
       * @default false
       */
      showMemberShip?: boolean;
      /**
       * showProfile
       * @default false
       */
      showProfile?: boolean;
      /**
       * showReward
       * @default false
       */
      showReward?: boolean;
      /**
       * size
       * @format int32
       * @default 10
       */
      size?: number;
      /**
       * sortAsc
       * @default false
       */
      sortAsc?: boolean;
      /** sortType */
      sortType?:
        | "CREATED_AT"
        | "DISPLAY_NAME"
        | "USER_EMAIL"
        | "USER_LOGIN"
        | "USER_NICK_NAME";
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/v2/user_list`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ITU User Controller
   * @name VerifyNickNameUsingGet
   * @summary verify uniqueness of nickname
   * @request GET:/server/users/verify_nick_name
   */
  verifyNickNameUsingGet = (
    query: {
      /** nickName */
      nickName: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/users/verify_nick_name`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Video View History
   * @name StartPlayVideoUsingPost
   * @summary Record start watching video
   * @request POST:/server/videoView/start_play
   */
  startPlayVideoUsingPost = (
    dto: StartVideoPlayDTO,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/videoView/start_play`,
      method: "POST",
      body: dto,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Video View History
   * @name EndPlayVideoUsingGet
   * @summary Update duration watching video
   * @request GET:/server/videoView/update_duration_time
   */
  endPlayVideoUsingGet = (
    query: {
      /**
       * duration
       * @format int64
       */
      duration: number;
      /**
       * id
       * @format int64
       */
      id: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/videoView/update_duration_time`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Zoom Controller
   * @name GetCommunityCafeZoomMeetingInfoUsingGet
   * @summary get join Community Cafe Meeting info
   * @request GET:/server/zoom/community_cafe_meeting_info
   */
  getCommunityCafeZoomMeetingInfoUsingGet = (params: RequestParams = {}) =>
    this.http.request<Record<string, object>, any>({
      path: `/server/zoom/community_cafe_meeting_info`,
      method: "GET",
      ...params,
    });
}
