import React, { useCallback, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import api from "../../services/api";
import Pagination from "../../components/Pagination";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import AgMenu from "../../components/AgMenu";
dayjs.extend(timezone);
dayjs.extend(utc);

1717136945186;
const IPNDataTable = ({ customerEmail }: { customerEmail: string }) => {
  const [pageQuery, setPageQuery] = useState({
    current: 1,
    size: 20,
  });
  const [total, setTotal] = useState(0);
  const gridRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<Array<any>>([]);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "date",
      headerName: "Transaction Date Time",
      width: 200,
      visibility: true,
      cellRenderer: (params: { value: number }) => {
        return (
          <span>
            {dayjs(params.value)
              .tz("America/Los_Angeles")
              .format("DD/MM/YYYY HH:mm")}
          </span>
        );
      },
    },
    {
      field: "eventType",
      headerName: "Event Type",
      width: 200,
      visibility: true,
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      width: 200,
      visibility: true,
    },
    { field: "amount", headerName: "Amount", width: 200, visibility: true },
    {
      field: "buyerEmail",
      headerName: "Buyer Email",
      width: 200,
      visibility: true,
    },
    {
      field: "buyerFirstName",
      headerName: "Buyer First Name",
      width: 200,
      visibility: true,
    },
    { field: "buyerIp", headerName: "Buyer ip", width: 200, visibility: true },
    {
      field: "buyerLastName",
      headerName: "Buyer Last Name",
      width: 200,
      visibility: true,
    },
    {
      field: "campaignId",
      headerName: "Campaign Id",
      width: 200,
      visibility: true,
    },
    {
      field: "campaignName",
      headerName: "Campaign Name",
      width: 200,
      visibility: true,
    },
    {
      field: "dealCreatedDateTime",
      headerName: "Deal Created Date Time",
      width: 200,
      visibility: true,
      cellRenderer: (params: { value: number }) => {
        return (
          <span>
            {params.value
              ? dayjs(params.value)
                  .tz("America/Los_Angeles")
                  .format("DD/MM/YYYY HH:mm")
              : "--"}
          </span>
        );
      },
    },
    { field: "dealId", headerName: "Deal Id", width: 200, visibility: true },
    {
      field: "invoiceId",
      headerName: "Invoice Id",
      width: 200,
      visibility: true,
    },
    {
      field: "isRebill",
      headerName: "Is rebill",
      width: 200,
      visibility: true,
    },
    { field: "mode", headerName: "Mode", width: 200, visibility: true },
    {
      field: "paymentProcessor",
      headerName: "Payment processor",
      width: 200,
      visibility: true,
    },
    {
      field: "processed",
      headerName: "Processed",
      width: 200,
      visibility: true,
    },
    {
      field: "productId",
      headerName: "Product id",
      width: 200,
      visibility: true,
    },
    {
      field: "productName",
      headerName: "Product name",
      width: 200,
      visibility: true,
    },
    { field: "quantity", headerName: "Quantity", width: 200, visibility: true },
    {
      field: "transactionTimestamp",
      headerName: "Transaction Time Stamp",
      width: 200,
      visibility: true,
    },
    {
      field: "transactionTime",
      headerName: "Transaction Time",
      width: 200,
      cellRenderer: (params: { value: number }) => {
        return (
          <span>
            {dayjs(params.value * 1000)
              .tz("America/Los_Angeles")
              .format("DD/MM/YYYY HH:mm")}
          </span>
        );
      },
      visibility: true,
    },
  ]);

  const getTableData = async (p = pageQuery) => {
    setLoading(true);
    api
      .messagePageUsingGet({
        ...p,
        buyerEmail: customerEmail,
      })
      .catch((e) => e)
      .then((res) => {
        if (res && res.status === 200) {
          setTotal(res.data?.data?.total || 0);
          setRowData(res.data?.data?.records || []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    setPageQuery(p);
  };

  const onGridReady = useCallback(() => {
    getTableData();
  }, []);

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: "70vh",
        width: "98vw",
      }}
    >
      {gridRef && gridRef.current && (
        <AgMenu
          columnDefs={columnDefs}
          gridRef={gridRef}
          setColumnDefs={setColumnDefs}
        ></AgMenu>
      )}
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        suppressDragLeaveHidesColumns={true}
        onGridReady={onGridReady}
      />
      <Pagination
        loading={isLoading}
        current={pageQuery.current}
        size={pageQuery.size}
        total={total}
        onChange={(current: number, size: number) => {
          getTableData({ current, size });
        }}
      />
    </div>
  );
};

export default IPNDataTable;
