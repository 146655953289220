import React from "react";
import Layout from "../../../components/layout";
import {
  Box,
  Heading,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

const HistoricalSubscriptionPage = () => {
  const toast = useToast();

  return (
    <Layout>
      <Box p="4">
        <Heading>Historical Subscription Load</Heading>

        <Box w="600px" pt={12}>
          <Box display={"flex"} alignItems={"center"}>
            <Box flexShrink={0}>Load State:</Box>{" "}
            <Input placeholder="Basic usage" />
          </Box>
          <Box display={"flex"} gap={2} pt={4}>
            <LoadData
              onProceed={() => {
                toast({
                  position: "top",
                  status: "success",
                  description: "Load Started. Refresh Page For State Updates.",
                });
              }}
            />
            <ClearData
              onProceed={() => {
                toast({
                  position: "top",
                  status: "success",
                  description: "Tracking Record Cleared.",
                });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

const LoadData = ({ onProceed }: { onProceed: () => void }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button colorScheme="green" onClick={onOpen}>
        Load Data
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            <p>Do you want to load unprocessed historical subscription.</p>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                onClose();
                onProceed();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const ClearData = ({ onProceed }: { onProceed: () => void }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button colorScheme="red" onClick={onOpen}>
        Clear Job From Running State Tracker
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            <p>Do you want to clear tracking?</p>
            <p>Make sure allHangfire Jobs are cleared before proceeding.</p>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                onClose();
                onProceed();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HistoricalSubscriptionPage;
