import React, { useCallback, useMemo, useRef, useState } from "react";
import Layout from "../../components/layout";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Button,
  Spacer,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import api from "../../services/api";
import Pagination from "../../components/Pagination";
import AgMenu from "../../components/AgMenu";
import DealsTable from "./DealsTable";
import TransactionsTable from "./TransactionsTable";
import HistoricalSubscriptionsTable from "./HistoricalSubscriptionsTable";
import HistoricalTransactionsTable from "./HistoricalTransactionsTable";
import IPNDateTable from "./IPNDataTable";
import SubscriptionsTable from "./SubscriptionsTable";
import {
  ContractDTO,
  DealStatusEnum,
  LastDealStageEnum,
} from "../../services/types";

const ContactPage = () => {
  const [pageQuery, setPageQuery] = useState({
    current: 1,
    size: 20,
    emailAddress: "",
    firstName: "",
    lastName: "",
  });
  const toast = useToast();
  const [keyword, setKeyword] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const gridRef = useRef<any>();
  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<Array<any>>([]);
  const [detail, setDetail] = useState<ContractDTO | null>(null);
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      filter: true,
      visibility: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      filter: true,
      visibility: true,
    },
    {
      field: "emailAddress",
      headerName: "Email Name",
      flex: 1,
      filter: true,
      visibility: true,
    },
    {
      field: "lastDealStage",
      headerName: "Last Deal Stage",
      flex: 1,
      visibility: true,
      cellRenderer: (params: { value: number }) => {
        return <span>{LastDealStageEnum[params.value]}</span>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      visibility: true,
      cellRenderer: (params: { value: number }) => {
        return <span>{DealStatusEnum[params.value]}</span>;
      },
    },
    {
      field: "action",
      headerName: "id",
      visibility: true,
      cellRenderer: (params: { data: any }) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setShowDetail(true);
                setDetail(params.data);
              }}
              icon={<EditIcon />}
            />
          </Flex>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
    };
  }, []);

  // useEffect(() => {
  //   if (detail) {
  //     detail.id
  //   }
  // }, [detail])
  const getTableData = async (p = pageQuery) => {
    setLoading(true);
    api
      .contractsPageUsingGet(p)
      .catch((e) => e)
      .then((res) => {
        if (res && res.status === 200) {
          setTotal(res.data?.data?.total || 0);
          setRowData(res.data?.data?.records || []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    setPageQuery(p);
  };

  const onGridReady = useCallback((params: any) => {
    getTableData();
  }, []);

  return (
    <Layout>
      <Flex gap={1} marginBottom={"24px"} marginTop={"24px"} padding={"0 24px"}>
        <Spacer flex={1}>
          <FormControl>
            <FormLabel>Email Address</FormLabel>
            <Input
              type="text"
              value={pageQuery.emailAddress}
              onChange={(event) =>
                setPageQuery((_pageQuery) => {
                  return {
                    ..._pageQuery,
                    emailAddress: event.target.value,
                  };
                })
              }
            />
          </FormControl>
        </Spacer>
        <Spacer flex={1}>
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              value={pageQuery.firstName}
              onChange={(event) =>
                setPageQuery((_pageQuery) => {
                  return {
                    ..._pageQuery,
                    firstName: event.target.value,
                  };
                })
              }
            />
          </FormControl>
        </Spacer>
        <Spacer flex={1}>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              value={pageQuery.lastName}
              onChange={(event) =>
                setPageQuery((_pageQuery) => {
                  return {
                    ..._pageQuery,
                    lastName: event.target.value,
                  };
                })
              }
            />
          </FormControl>
        </Spacer>
      </Flex>
      <Flex
        padding={"0 24px"}
        gap={1}
        marginBottom={"24px"}
        marginTop={"24px"}
        justifyContent={"flex-end"}
      >
        <Button
          colorScheme="gray"
          onClick={() => {
            setPageQuery((_pageQuery) => {
              return {
                ..._pageQuery,
                lastName: "",
                firstName: "",
                emailAddress: "",
              };
            });
          }}
        >
          Reset
        </Button>
        <Button
          onClick={() => {
            setPageQuery((_pageQuery) => {
              return {
                ..._pageQuery,
                current: 1,
              };
            });
            getTableData({ ...pageQuery, current: 1 });
          }}
          colorScheme="blue"
          marginLeft={"24px"}
        >
          Search
        </Button>
      </Flex>
      <div
        className="ag-theme-alpine"
        style={{
          position: "relative",
          height: "85%",
          width: "100%",
        }}
      >
        {gridRef && gridRef.current && (
          <AgMenu
            columnDefs={columnDefs}
            gridRef={gridRef}
            setColumnDefs={setColumnDefs}
          ></AgMenu>
        )}
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressDragLeaveHidesColumns={true}
          onGridReady={onGridReady}
        />
        <Pagination
          loading={isLoading}
          current={pageQuery.current}
          size={pageQuery.size}
          total={total}
          onChange={(current: number, size: number) => {
            getTableData({ ...pageQuery, current, size });
          }}
        />
        <Modal
          onClose={() => setShowDetail(false)}
          size={"full"}
          isOpen={showDetail}
        >
          <ModalOverlay />
          <ModalContent
            style={{
              overflowX: "hidden",
            }}
          >
            <ModalHeader>Contact</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Tabs
                index={tabIndex}
                onChange={(e) => {
                  setTabIndex(e);
                }}
              >
                <TabList>
                  <Tab>Config</Tab>
                  <Tab>Deals</Tab>
                  <Tab>Transactions</Tab>
                  <Tab>Historical Subscriptions</Tab>
                  <Tab>Historical Transactions</Tab>
                  <Tab>IPN Data</Tab>
                  <Tab>Subscriptions</Tab>
                </TabList>
              </Tabs>
              {detail ? (
                tabIndex === 0 ? (
                  <div>
                    <FormControl marginTop={"24px"}>
                      <Checkbox
                        checked={detail.processDeals === 1}
                        onChange={(e) => {
                          setDetail((_detail) => {
                            return {
                              ..._detail,
                              processDeals: e.target.checked ? 1 : 0,
                            };
                          });
                        }}
                      >
                        Process Deals
                      </Checkbox>
                    </FormControl>
                    <Flex marginTop={"24px"} gap={"48px"}>
                      <FormControl>
                        <FormLabel>Email Address</FormLabel>
                        <Input
                          value={detail.emailAddress}
                          onChange={(e) => {
                            setDetail((_detail) => {
                              return {
                                ..._detail,
                                emailAddress: e.target.value,
                              };
                            });
                          }}
                          placeholder="Email Address"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Hubspot Contact Id</FormLabel>
                        <Input
                          value={detail.hubspotContactId}
                          onChange={(e) => {
                            setDetail((_detail) => {
                              return {
                                ..._detail,
                                hubspotContactId: Number(e.target.value),
                              };
                            });
                          }}
                          placeholder="Hubspot Contact Id"
                        />
                      </FormControl>
                    </Flex>
                    <Flex marginTop={"24px"} gap={"48px"}>
                      <FormControl>
                        <FormLabel>First name</FormLabel>
                        <Input
                          value={detail.firstName}
                          onChange={(e) => {
                            setDetail((_detail) => {
                              return {
                                ..._detail,
                                firstName: e.target.value,
                              };
                            });
                          }}
                          placeholder="First name"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Last Deal Stage</FormLabel>
                        <Select
                          disabled
                          value={detail.lastDealStage}
                          onChange={(e) => {
                            setDetail((_detail) => {
                              return {
                                ..._detail,
                                lastDealStage: Number(e.target.value),
                              };
                            });
                          }}
                          placeholder="Select option"
                        >
                          {Object.values(LastDealStageEnum).map(
                            (el: number) => (
                              <option key={el} value={LastDealStageEnum[el]}>
                                {el}
                              </option>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Flex>
                    <Flex marginTop={"24px"} gap={"48px"}>
                      <FormControl>
                        <FormLabel>Last name</FormLabel>
                        <Input
                          value={detail.lastName}
                          onChange={(e) => {
                            setDetail((_detail) => {
                              return {
                                ..._detail,
                                lastName: e.target.value,
                              };
                            });
                          }}
                          placeholder="Last name"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Status</FormLabel>
                        <Select
                          disabled
                          value={detail.status}
                          onChange={(e) => {
                            setDetail((_detail) => {
                              return {
                                ..._detail,
                                status: Number(e.target.value),
                              };
                            });
                          }}
                          placeholder="Select option"
                        >
                          {Object.values(DealStatusEnum).map((el: number) => (
                            <option key={el} value={DealStatusEnum[el]}>
                              {el}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Flex>
                  </div>
                ) : tabIndex === 1 ? (
                  <DealsTable emailAddress={detail.emailAddress}></DealsTable>
                ) : tabIndex === 2 ? (
                  <TransactionsTable contactId={detail.id}></TransactionsTable>
                ) : tabIndex === 3 ? (
                  <HistoricalSubscriptionsTable
                    customerEmail={detail.emailAddress}
                  ></HistoricalSubscriptionsTable>
                ) : tabIndex === 4 ? (
                  <HistoricalTransactionsTable
                    customerEmail={detail.emailAddress}
                  ></HistoricalTransactionsTable>
                ) : tabIndex === 5 ? (
                  <IPNDateTable
                    customerEmail={detail.emailAddress}
                  ></IPNDateTable>
                ) : tabIndex === 6 ? (
                  <SubscriptionsTable
                    contactId={detail.id}
                  ></SubscriptionsTable>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </ModalBody>
            <ModalFooter>
              {tabIndex === 0 && (
                <Button
                  colorScheme="blue"
                  onClick={() =>
                    api
                      .updateContractsUsingPut(detail.id, detail)
                      .catch((e) => e)
                      .then((res) => {
                        if (res.status === 200 && res.data?.code === 200) {
                          toast({
                            title: "Contract update",
                            description: "Contract update success",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        }
                      })
                  }
                >
                  Save
                </Button>
              )}

              <Button marginLeft={"24px"} onClick={() => console.log("1111")}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </Layout>
  );
};

export default ContactPage;
