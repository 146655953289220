import React, { useCallback, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import api from "../../services/api";
import Pagination from "../../components/Pagination";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import AgMenu from "../../components/AgMenu";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.extend(utc);

const DealsTable = ({ emailAddress }: { emailAddress: string }) => {
  const [pageQuery, setPageQuery] = useState({
    current: 1,
    size: 20,
  });
  const [total, setTotal] = useState(0);
  const gridRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<Array<any>>([]);

  const [columnDefs, setColumnDefs] = useState([
    { field: "stage", headerName: "Stage", width: 200, visibility: true },
    { field: "amount", headerName: "Amount", width: 200, visibility: true },
    { field: "campaign", headerName: "Campaign", width: 200, visibility: true },
    {
      field: "couponCode",
      headerName: "Coupon Code",
      width: 200,
      visibility: true,
    },
    {
      field: "customFields",
      headerName: "Custom Fields",
      width: 200,
      visibility: true,
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 200,
      visibility: true,
      cellRenderer: (params: { value: number }) => {
        return (
          <span>
            {dayjs(params.value).tz("America/Los_Angeles").format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      field: "dealType",
      headerName: "Deal Type",
      visibility: true,
      width: 200,
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      width: 200,
      filter: true,
      visibility: true,
    },
    {
      field: "hasSyncError",
      headerName: "Has Sync Error",
      visibility: true,
      width: 200,
    },
    {
      field: "hubspotDealId",
      headerName: "Hubspot Deal Id",
      visibility: true,
      width: 200,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      visibility: true,
      filter: true,
    },
    {
      field: "needsSynchronization",
      headerName: "Needs Synchronization",
      visibility: true,
      width: 200,
    },
    {
      field: "nextBillDate",
      headerName: "Next Bill Date",
      visibility: true,
      width: 200,
      cellRenderer: (params: { value: number }) => {
        return (
          <span>
            {dayjs(params.value).tz("America/Los_Angeles").format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    { field: "pipeline", headerName: "Pipeline", visibility: true, width: 200 },
    {
      field: "productId",
      headerName: "Product Id",
      visibility: true,
      width: 200,
    },
    {
      field: "productName",
      headerName: "Product Name",
      visibility: true,
      width: 200,
      filter: true,
    },
    {
      field: "productPrice",
      headerName: "Product Price",
      visibility: true,
      width: 200,
    },
    {
      field: "recurrenceInterval",
      headerName: "Recurrence Interval",
      visibility: true,
      width: 200,
    },
    {
      field: "recurrenceValue",
      headerName: "Recurrence Value",
      visibility: true,
      width: 200,
    },
    {
      field: "subscriptionId",
      headerName: "Subscription Id",
      visibility: true,
      width: 200,
    },
    {
      field: "emailAddress",
      visibility: true,
      headerName: "Subscription Start Date",
      width: 200,
    },
    {
      field: "syncError",
      headerName: "Sync Error",
      visibility: true,
      width: 200,
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      visibility: true,
      width: 200,
    },
    {
      field: "transactionRecordId",
      visibility: true,
      headerName: "Transaction Record Id",
      width: 200,
    },
  ]);

  const getTableData = async (p = pageQuery) => {
    setLoading(true);
    api
      .dealsPageUsingGet({ ...p, emailAddress: emailAddress })
      .catch((e) => e)
      .then((res) => {
        if (res && res.status === 200) {
          setTotal(res.data?.data?.total || 0);
          setRowData(res.data?.data?.records || []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    setPageQuery(p);
  };

  const onGridReady = useCallback(() => {
    getTableData();
  }, []);

  return (
    <div
      className="ag-theme-alpine"
      style={{
        position: "relative",
        height: "70vh",
        width: "98vw",
      }}
    >
      {gridRef && gridRef.current && (
        <AgMenu
          columnDefs={columnDefs}
          gridRef={gridRef}
          setColumnDefs={setColumnDefs}
        ></AgMenu>
      )}
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        suppressDragLeaveHidesColumns={true}
        onGridReady={onGridReady}
      />
      <Pagination
        loading={isLoading}
        current={pageQuery.current}
        size={pageQuery.size}
        total={total}
        onChange={(current: number, size: number) => {
          getTableData({ current, size });
        }}
      />
    </div>
  );
};

export default DealsTable;
