import React, { useState, useEffect, useRef, useMemo } from "react";
import Layout from "../../../components/layout";
import {
  Box,
  Heading,
  Flex,
  ModalHeader,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  IconButton,
  useToast,
  Select,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import api from "../../../services/api";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-community";
import ReactSelect from "react-select";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { ModuleRegistry } from "@ag-grid-community/core";
const selectOptions = ["Single", "Daily", "Monthly", "Yearly"];

ModuleRegistry.registerModules([SideBarModule]);

const CampaignPage = () => {
  const gridRef = useRef();
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<any>();
  const [tableData, setTableData] = useState([]);

  const getPageData = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await api
      .productsPageUsingGet({ current: 1, size: -1 })
      .catch((e) => e)
      .finally(() => setLoading(false));
    setTableData(res.data?.data?.records || []);
  };
  useEffect(() => {
    getPageData();
  }, []);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: "Product ID",
        field: "productId",
        width: 140,
        filter: true,
      },
      {
        headerName: "Name",
        field: "productName",
        flex: 1,
        minWidth: 200,
        filter: true,
      },
      { headerName: "Price", field: "price", width: 100, filter: true },
      {
        headerName: "Recurrence Interval",
        field: "recurrenceInterval",
        width: 190,
        filter: true,
        cellRenderer: (params: any) => {
          return selectOptions?.[params.data.recurrenceInterval] || "";
        },
      },
      {
        headerName: "Recurrence Value",
        field: "recurrenceValue",
        width: 180,
        filter: true,
      },
      {
        headerName: "Trial Price",
        field: "trialPrice",
        width: 140,
        filter: true,
      },
      {
        headerName: "Trial Length",
        field: "trialLength",
        width: 140,
        filter: true,
      },
      {
        headerName: "Action",
        cellRenderer: (params: { data: any }) => {
          return (
            <Flex h={"100%"} alignItems={"center"} gap={4}>
              <IconButton
                size={"sm"}
                aria-label="edit"
                onClick={() => setDetail(params.data)}
                icon={<EditIcon />}
              />
            </Flex>
          );
        },
      },
    ],
    []
  );

  return (
    <Layout>
      {detail ? (
        <Detail
          detail={detail}
          onClose={() => setDetail(null)}
          onRefresh={() => {
            getPageData();
          }}
        />
      ) : null}
      <Box p="4" w="100%" h="100%" position={"relative"}>
        <Flex justifyContent={"space-between"} h={"44px"}>
          <Heading>Products</Heading>
          <Button
            colorScheme="teal"
            variant="solid"
            size="md"
            onClick={() => setDetail({})}
          >
            Add{" "}
          </Button>
        </Flex>
        <div
          className="ag-theme-alpine"
          style={{
            height: "calc(100% - 44px )",
          }}
        >
          <AgGridReact
            sideBar
            ref={gridRef}
            rowData={tableData}
            columnDefs={columnDefs}
            paginationPageSize={20}
            pagination
          />
        </div>
      </Box>
    </Layout>
  );
};

const Detail = ({
  detail,
  onRefresh,
  onClose,
}: {
  detail: any;
  onRefresh: () => void;
  onClose: () => void;
}) => {
  const toast = useToast();
  const [data, setData] = useState(detail || {});
  const [loading, setLoading] = useState(false);
  const onSave = async () => {
    setLoading(true);
    const res = await (data.id
      ? api.updateProductsUsingPut(data.id, data)
      : api.createProductsUsingPost(data)
    )
      .catch((e) => e)
      .finally(() => setLoading(false));
    if (res.data.code === 200) {
      toast({
        status: "success",
        description: data.id ? "save success" : "create success",
      });
      onRefresh();
      onClose();
    } else {
      toast({
        status: "error",
        description: res.data.msg || "error",
      });
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        if (!loading) {
          onClose?.();
        }
      }}
      isCentered
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{data?.id ? "Edit" : "Create"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Campaign ID:
            </Box>
            <Box w={"100%"}>
              <CampaignSelect
                value={data.campaignId}
                onChange={(campaignId) => setData({ ...data, campaignId })}
              />
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Product ID:
            </Box>
            <Input
              value={data.productId}
              placeholder="please input"
              onChange={(e) => setData({ ...data, productId: e.target.value })}
            />
          </Box>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Product Name:
            </Box>
            <Input
              value={data.productName}
              placeholder="please input"
              onChange={(e) =>
                setData({ ...data, productName: e.target.value })
              }
            />
          </Box>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Trial Length:
            </Box>
            <Input
              type="number"
              value={data.trialLength}
              placeholder="please input"
              onChange={(e) =>
                setData({ ...data, trialLength: +e.target.value })
              }
            />
          </Box>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Trial Price:
            </Box>
            <Input
              type="number"
              value={data.trialPrice}
              placeholder="please input"
              onChange={(e) =>
                setData({ ...data, trialPrice: +e.target.value })
              }
            />
          </Box>

          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Recurrence Interval:
            </Box>
            <Select
              value={data.recurrenceInterval}
              placeholder="please elect"
              onChange={(e) =>
                setData({ ...data, recurrenceInterval: +e.target.value })
              }
            >
              {selectOptions.map((name, index) => (
                <option key={index} value={index}>
                  {name}
                </option>
              ))}
            </Select>
          </Box>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Recurrence Value:
            </Box>
            <Input
              type="number"
              value={data.recurrenceValue}
              placeholder="please input"
              onChange={(e) =>
                setData({ ...data, recurrenceValue: +e.target.value })
              }
            />
          </Box>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Price:
            </Box>
            <Input
              type="number"
              value={data.price}
              placeholder="please input"
              onChange={(e) => setData({ ...data, price: +e.target.value })}
            />
          </Box>

          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Alternate Name1:
            </Box>
            <Input
              value={data.alternateName1}
              placeholder="please input"
              onChange={(e) =>
                setData({ ...data, alternateName1: e.target.value })
              }
            />
          </Box>

          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Alternate Name2:
            </Box>
            <Input
              value={data.alternateName2}
              placeholder="please input"
              onChange={(e) =>
                setData({ ...data, alternateName2: e.target.value })
              }
            />
          </Box>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Alternate Name3:
            </Box>
            <Input
              value={data.alternateName3}
              placeholder="please input"
              onChange={(e) =>
                setData({ ...data, alternateName3: e.target.value })
              }
            />
          </Box>
          <Box display={"flex"} alignItems={"center"} py={2}>
            <Box w="130px" flexShrink={0}>
              {" "}
              Alternate Name4:
            </Box>
            <Input
              value={data.alternateName4}
              placeholder="please input"
              onChange={(e) =>
                setData({ ...data, alternateName4: e.target.value })
              }
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button disabled={loading} mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button isLoading={loading} colorScheme="blue" onClick={onSave}>
            {data.id ? "Save" : "Create"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const CampaignSelect = ({
  value,
  onChange,
}: {
  value: any;
  onChange: (id: number) => void;
}) => {
  const [campaignOptions, setCampaignOptions] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const getampaignOptions = async () => {
    setLoading(true);
    const res = await api
      .campaignsPageUsingGet({ current: 1, size: 100000 })
      .catch((e) => e)
      .finally(() => {
        setLoading(false);
      });
    const options = (res.data?.data?.records || []).map(
      ({ id, campaignName }: any) => ({
        label: campaignName,
        value: id,
      })
    );
    setSelected(options.find((item: any) => item.value === value));
    setCampaignOptions(options);
  };

  useEffect(() => {
    getampaignOptions();
  }, []);

  return (
    <ReactSelect
      isLoading={loading}
      value={selected}
      options={campaignOptions}
      onChange={(newValue) => {
        setSelected(newValue);
        onChange(newValue.value);
      }}
    />
  );
};

export default CampaignPage;
