import React, { useCallback, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import api from "../../services/api";
import Pagination from "../../components/Pagination";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import AgMenu from "../../components/AgMenu";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.extend(utc);

const TransactionsTable = ({ contactId }: { contactId: number }) => {
  const [pageQuery, setPageQuery] = useState({
    current: 1,
    size: 20,
  });
  const [total, setTotal] = useState(0);
  const gridRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<Array<any>>([]);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "date",
      headerName: "Date",
      width: 200,
      visibility: true,
      cellRenderer: (params: { value: number }) => {
        return (
          <span>
            {dayjs(params.value).tz("America/Los_Angeles").format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      field: "transactionType",
      headerName: "Transaction Type",
      width: 200,
      visibility: true,
    },
    { field: "status", headerName: "Status", width: 200, visibility: true },
    { field: "amount", headerName: "Amount", width: 200, visibility: true },
    {
      field: "productPrice",
      headerName: "Product Price",
      width: 200,
      visibility: true,
    },
    {
      field: "productId",
      headerName: "Product Id",
      width: 200,
      visibility: true,
    },
    { field: "campaign", headerName: "Campaign", width: 200, visibility: true },
    {
      field: "couponCode",
      headerName: "Coupon Code",
      width: 200,
      visibility: true,
    },
    {
      field: "customFields",
      headerName: "Custom Fields",
      width: 200,
      visibility: true,
    },
    { field: "currency", headerName: "Currency", width: 200, visibility: true },
    {
      field: "errorDetail",
      headerName: "Error Detail",
      width: 200,
      visibility: true,
    },
    { field: "product", headerName: "Product", width: 200, visibility: true },
    {
      field: "subscriptionId",
      headerName: "Subscription Id",
      width: 200,
      visibility: true,
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      width: 200,
      visibility: true,
    },
  ]);

  const getTableData = async (p = pageQuery) => {
    setLoading(true);
    api
      .transactionQueuesPageUsingGet({ ...p, contactId: contactId })
      .catch((e) => e)
      .then((res) => {
        if (res && res.status === 200) {
          setTotal(res.data?.data?.total || 0);
          setRowData(res.data?.data?.records || []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    setPageQuery(p);
  };

  const onGridReady = useCallback(() => {
    getTableData();
  }, []);

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: "70vh",
        width: "98vw",
      }}
    >
      {gridRef && gridRef.current && (
        <AgMenu
          columnDefs={columnDefs}
          gridRef={gridRef}
          setColumnDefs={setColumnDefs}
        ></AgMenu>
      )}
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        suppressDragLeaveHidesColumns={true}
        onGridReady={onGridReady}
      />
      <Pagination
        loading={isLoading}
        current={pageQuery.current}
        size={pageQuery.size}
        total={total}
        onChange={(current: number, size: number) => {
          getTableData({ current, size });
        }}
      />
    </div>
  );
};

export default TransactionsTable;
