import lifecycles from "./impact-theory-data-sync-hubspot";

// export const ituSubMenu = [
//   {
//     label: 'View All Users',
//     subLabel: 'View All Users',
//     href: '/itu/users'
//   }
// ]

export const { bootstrap, mount, unmount } = lifecycles;
