import React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";
import HomePage from "./pages/home";
import ContactPage from "./pages/contacts";
import CampaignPage from "./pages/setup/campaign";
import ProductPage from "./pages/setup/product";
import EmailRecipientPage from "./pages/setup/email-recipient";
import BackgroundJobMonitorPage from "./pages/system/background-job-monitor";
import HistoricalTransactionPage from "./pages/system/historical-transaction";
import HistoricalSubscriptionPage from "./pages/system/historical-subscription";
import IPNProcessingPage from "./pages/system/ipn-processing";
import TransactionProcessingPage from "./pages/system/transaction-processing";
import DealProcessingPage from "./pages/system/deal-processing";

const router = createBrowserRouter([
  {
    path: "/data-sync-hubspot",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "contact",
        element: <ContactPage />,
      },
      {
        path: "setup",
        element: <Outlet />,
        children: [
          {
            path: "campaign",
            element: <CampaignPage />,
          },
          {
            path: "product",
            element: <ProductPage />,
          },
          // {
          //   path: "email-recipient",
          //   element: <EmailRecipientPage />,
          // },
        ],
      },
      {
        path: "system",
        element: <Outlet />,
        children: [
          {
            path: "background-job-monitor",
            element: <BackgroundJobMonitorPage />,
          },
          {
            path: "historical-transaction",
            element: <HistoricalTransactionPage />,
          },
          {
            path: "historical-subscription",
            element: <HistoricalSubscriptionPage />,
          },
          {
            path: "pin-processing",
            element: <IPNProcessingPage />,
          },
          {
            path: "transaction-processing",
            element: <TransactionProcessingPage />,
          },
          {
            path: "deal-processing",
            element: <DealProcessingPage />,
          },
        ],
      },
    ],
  },
]);

export default router;
